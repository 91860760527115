import {Box, Button, Container, Grid, Pagination, Stack, Tab, Tabs, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {
    TestQuestionPreviewDto,
    TestQuestionsFilterSort,
    TestQuestionsSearchDto
} from "../../actions/testQuestionsSearch.type";
import SortSelect from "../components/SortSelect";
import QuestionCategorySelect from "../components/QuestionsCategorySelect";
import {getQuestionsSearch} from "../../actions/api";
import useLoadingHook from "../../../global/hooks/UseLoadingHook";
import QuestionCard from "../components/QuestionCard";
import {useNavigate} from "react-router-dom";
import SearchInput from "../../../global/components/SearchInput";
import PageHeader from "../../../global/components/PageHeader";
import {wrapperSx} from "../../../global/helpers/globalstyles";



export const QuestionsList = () => {
    const navigate = useNavigate()
    const [search, setSearch] = useState<string>('');
    const [sort, setSort] = useState<TestQuestionsFilterSort | null>(TestQuestionsFilterSort.created_at_desc);
    const [page, setPage] = useState<number>(1);
    const [categoryId, setCategoryId] = useState<number>(-1);
    const countPerPage = 60;
    const [questions, questionsLoading, questionsError, fetchQuestions] = useLoadingHook(getQuestionsSearch)

    useEffect(() => {
        fetchQuestions({
            count: countPerPage,
            offset: (page - 1) * countPerPage,
            q: search || undefined,
            sort: sort ? [sort] : undefined,
            category_id: categoryId === -1 ? undefined : categoryId,
        });
    }, [categoryId, sort, page, search]);


    return (
            <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <PageHeader />
                <Box sx={{...wrapperSx, pb: 3}}>
                    <Container maxWidth="xl" sx={{mb: 5, mt: 3}}>
                        <Stack flexDirection="row" justifyContent={'space-between'} flexWrap="wrap"
                               alignItems={'center'}
                               sx={{mb: 1}}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Typography variant="h2">База вопросов ПДД </Typography>
                            </Stack>
                            <Button startIcon={<Add/>} onClick={() => navigate(`/questions/new`)}>Создать вопрос</Button>
                        </Stack>
                        <Tabs value={1} sx={{mb: 3}} variant="fullWidth">
                            <Tab label="Обзор" value={1} onClick={() => navigate(`/questions`)}/>
                            <Tab label="Предпросмотр" value={2} onClick={() => navigate(`/questions/demo`)}/>
                        </Tabs>
                        <Stack flexDirection="row" alignItems="center" flexWrap="wrap" sx={{mb: 4}}>
                            <SearchInput
                                placeholder="Поиск по ID/названию" sx={{width: '40%', minWidth: 250, maxWidth: 400, mr: 2}}
                                onChange={(e) => {
                                    setSearch(e.currentTarget.value);
                                    setPage(1);
                                }} size="small"/>
                            <QuestionCategorySelect
                                fullWidth={false} search
                                categoryId={categoryId} onChange={(newValue) => {
                                setCategoryId(newValue);
                                setPage(1);
                            }}/>
                            <SortSelect
                                items={TestQuestionsFilterSort} sx={{maxWidth: 300, ml: 'auto'}}
                                onChange={(value) => {
                                    setSort(value as TestQuestionsFilterSort | null);
                                    setPage(1);
                                }}
                                value={sort}
                            />
                        </Stack>
                        <Grid container spacing={2} sx={{mb: 8}}>
                            {questions?.items?.map((item: TestQuestionPreviewDto) =>
                                <Grid item xs={12} md={6} lg={4} xl={4} key={item.id}>
                                    <QuestionCard key={item.id} data={item}/>
                                </Grid>
                            )}
                        </Grid>
                        <Stack flexDirection="row" justifyContent={'space-between'} flexWrap="wrap"
                               alignItems={'center'}
                               sx={{mb: 1}}>
                            <Typography color="gray">Всего тестов: {questions?.sum}</Typography>
                            <Pagination count={Math.ceil((questions?.sum || 0) / countPerPage)}
                                        showFirstButton showLastButton page={page} siblingCount={3}
                                        onChange={(e, value) => setPage(value)}
                            />
                        </Stack>
                    </Container>
                </Box>
            </Box>
        )

}