import React from 'react';
import {Avatar, Box, Tab, Tabs, Typography} from "@mui/material";
import {DataGrid, GridColDef, GridEventListener} from "@mui/x-data-grid";
import {getName} from "../../global/helpers/text";
import {blue} from "@mui/material/colors";
import {useNavigate} from "react-router-dom";

interface TicketAdminGridProps {
    openAppeal: (id: number) => void,
    rows: any[],
    setTab: (newTab: number) => void,
    tab: number,
    loading: boolean
};

export enum TicketStatus {
    Новое = 1,
    Администратор = 2,
    Пользователь = 3,
    Закрытое = 10
};

const NoRows = () => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 5}}>
            <Typography>
                Нет данных
            </Typography>
        </Box>
    )
}

const TicketAdminGrid = ({openAppeal, rows, setTab, tab, loading}: TicketAdminGridProps) => {

    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 90
        },
        {
            field: 'title',
            headerName: 'Тема обращения',
            width: 300
        },
        {
            field: 'user',
            headerName: 'Пользователь',
            width: 300,
            renderCell: ({row}) => {
                const name = getName(row.user);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'manager',
            headerName: 'Администратор',
            width: 300,
            renderCell: ({row}) => {
                if (row.manager) {
                    const name = getName(row.manager);
                    return <React.Fragment>
                        <Avatar
                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                         >
                            {name.partialName}
                        </Avatar>
                        {name.fullName}
                    </React.Fragment>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'status',
            headerName: 'Статус',
            width: 200,
            renderCell: ({row}) => {
                return TicketStatus[row.status]
            }
        },
    ];

    const tabs = {
        all: {id: 0, label: `Все`},
        created: {id: 1, label: `Новые`},
        answeredByAdmin: {id: 2, label: `Администратор`},
        answeredByUser: {id: 3, label: `Клиент`},
        closed: {id: 10, label: `Закрытые`}
    };

    const handleRowClick: GridEventListener<'rowClick'> = (
        params,
    ) => {
        navigate('/tickets/' + params.row.id)
    };

    return (
        <Box className={'appeals'}>
            <Tabs
                value={tab}
                onChange={(e, newValue) => setTab(newValue)}
                variant="scrollable"
                scrollButtons={false}
                sx={{mt: 2, ml: 2}}
            >
                {Object.values(tabs).map(tab => <Tab label={tab.label} key={tab.id} value={tab.id}/>)}
            </Tabs>
            <DataGrid
                className={'appeals-grid'}
                rows={rows}
                onRowClick={handleRowClick}
                columns={columns}
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                autoHeight={true}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
                slots={{
                    noRowsOverlay: NoRows
                }}
            />
        </Box>
    );
};

export default TicketAdminGrid;