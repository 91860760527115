import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { validateIIN } from '../../global/helpers/validators';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useUserStore } from '../../global/store/UserStore';

interface KursantAuthDialogProps {
  open: boolean,
  onClose: () => void,
}

interface UserInfoInterface {
  iin: string,
  last_name: string
}

const KursantAuthDialog = ({open, onClose} : KursantAuthDialogProps) => {

  const snackbar = useSnackbarStore((state) => state);
  const currentUser = useUserStore((state) => state);

  const [userInfo, setUserInfo] = useState<UserInfoInterface>({
    iin: '',
    last_name: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'iin') {
      if (value.includes(' ')) {
        snackbar.errorMessage('ИИН должен состоять только из цифр');
        return;
      }

      if (!/^\d+$/.test(value) && value !== "") {
        snackbar.errorMessage('ИИН должен содержать только цифры');
        return;
      }

      if (value.length > 12) {
        snackbar.errorMessage('ИИН должен состоять из 12 цифр');
        return;
      }
    }

    setUserInfo((prev) => {
      return {
        ...prev,
        [name]: e.target.value
      }
    })
  };

  const handleSubmit = () => {
    const isIINValid = validateIIN(userInfo.iin);

    if (!isIINValid) {
      snackbar.errorMessage('Вы ввели некорректный ИИН');
      return;
    }

    if (!userInfo.last_name) {
      snackbar.errorMessage('Необходимо ввести фамилию для продолжения');
    }

    currentUser.setUserInfo({
      iin: userInfo.iin,
      last_name: userInfo.last_name,
    });

    currentUser.setIsKursant(true);
    currentUser.setIsAdmin(false);

    onClose();
  }

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      onClose={() => {}}
    >
      <DialogTitle>
        <Typography variant={'h6'}>
          Введите свой ИИН и Фамилию
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2} sx={{py: 2}}>
          <TextField
            name={'iin'}
            value={userInfo.iin}
            label={'ИИН'}
            onChange={handleChange}
          />
          <TextField
            name={'last_name'}
            value={userInfo.last_name}
            label={'Фамилия'}
            onChange={handleChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'contained'}
          onClick={() => {handleSubmit()}}
        >
          Далее
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KursantAuthDialog;
