import React, { useEffect, useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { useUserStore } from '../../global/store/UserStore';
import { getSchoolsByUserId } from '../actions/api';
import { blue } from '@mui/material/colors';
import { DirectionsCar } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';

interface UsersSchoolsProps {
    userId: number
}

const UsersSchools = ({userId} : UsersSchoolsProps) => {

    const currentUser = useUserStore((state) => state);
    const navigate = useNavigate();
    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 90,
            sortable: true,
        },
        {
            field: 'bin',
            headerName: 'БИН учебного центра',
            width: 200,
            sortable: false,
        },
        {
            field: 'title',
            headerName: 'Название учебного центра',
            width: 500,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return <React.Fragment>
                    <Link
                      to={`/schools/${params.row.id}`}
                      style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                        <Avatar
                          sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2}}
                        >
                            <DirectionsCar fontSize="small"/>
                        </Avatar>
                        {params.value}
                    </Link>
                </React.Fragment>;
            }
        },
    ];

    useEffect(() => {
        if (currentUser.isAdmin || (Number(currentUser.id) === Number(userId))) {
            getSchoolsByUserId(userId)
                .then((res) => {
                    setRows(res.data);
                })
        }
    }, [])

    return (
        <Box sx={{mt: 3}}>
            <Typography variant="h5" color="dimgray">Закрепленные за пользователем центры</Typography>
            <DataGrid
                sx={{mt: 2}}
                columns={columns}
                rows={rows}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                autoHeight={true}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                loading={loading}
            />
        </Box>
    );
};

export default UsersSchools;
