import {KursantLoginDto} from "../../Auth/dto/AuthDto";
import axiosInstance from "../../global/actions/main.api";
import {apiConfigGuest, BACKEND_URL} from "../../global/actions/apiVariables";

export const getInfoKursant = (dto: KursantLoginDto) => {
    return axiosInstance.post(`${BACKEND_URL}/students/get-info`, dto, apiConfigGuest);
}

// Запись на вождение
export const getAllEmployeesFromRegion = (regionId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/employees/appointment/get/region/${regionId}`);
}
export const getAvailabeDaysByEmployeeId = (employeeId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/schedules/get/days/${employeeId}`);
}
export const getStudentPromocodesInfo = (data: any) => {
    // let queryString = `?iin=${iin}&last_name=${last_name}`
    // console.log(queryString)
    return axiosInstance.post(`${BACKEND_URL}/codes/get`, data);
}
export const getAvailableHoursByDateAndEmployeeId = (date: any, employeeId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/appointment/get/available-hours/${employeeId}`, {date});
}
export const createAppointment = (dto: any) => {
    return axiosInstance.post(`${BACKEND_URL}/appointment/create`, dto);
}