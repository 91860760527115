import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Paper,
    Select, SelectChangeEvent,
    Stack,
    SxProps,
    Theme, Tooltip,
    Typography
} from "@mui/material";
import {GroupDto} from "../../actions/group.type";
import {useSchoolStore} from "../../../School/store/SchoolStore";
import {attachMasterToGroup, detachMasterFromGroup} from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import ClearIcon from "@mui/icons-material/Clear";
import {getActiveSchoolMasters} from "../../../Employees/actions/api";
import { useUserStore } from '../../../global/store/UserStore';

const paperSx: SxProps<Theme> = {
    boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 6px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    padding: 3,
    borderRadius: 4
};

interface MastersWindowProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
}

interface DriversModalProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
    open: boolean;
    onClose: () => void;
}

const DriversModal = ({groupInfo, fetchGroupInfo, open, onClose} : DriversModalProps) => {

    const [mastersList, setMastersList] = useState<any[]>([]);
    const [masterId, setMasterId] = useState<string>('');
    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);

    useEffect(() => {
        getActiveSchoolMasters(Number(schoolInfo.id))
            .then((res) => {
                console.log(res);
                setMastersList(res.data);
            })
    }, []);

    const handleSelectChange = (e: SelectChangeEvent) => {
        setMasterId(e.target.value);
    }

    const handleAttach = () => {

        if (!groupInfo.id || !masterId) {
            snackbar.errorMessage('Необходимо выбрать мастера производственнного обучения');
            return;
        }

        const masterDto = {
            group_id: groupInfo.id,
            master_id: Number(masterId)
        }

        attachMasterToGroup(masterDto)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                Прикрепить мастера производственнного обучения к группе <i><b>{groupInfo.title}</b></i>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Select
                        onChange={handleSelectChange}
                        value={masterId}
                    >
                        {mastersList.map((teacher) => {
                            return (
                                <MenuItem value={teacher.id} key={teacher.id}>
                                    <Stack sx={{width: '100%'}} flexDirection="row" alignItems={'center'}>
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            {teacher.last_name[0]}{teacher.first_name[0]}
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack flexDirection="row" alignItems="center" justifyContent="space-between"
                                                   sx={{width: '100%'}}>
                                                <Typography>{teacher.last_name} {teacher.first_name}</Typography>
                                                <Typography variant="body2" color="gray">#{teacher.id}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            )
                        })}
                    </Select>

                    <Typography variant="body2" sx={{mt: 1}}>
                        {'Примечание: в списке мастеров производственнного обучения отображаются только те люди, которые прикреплены к этому учебному центру как мастера производственнного обучения. Чтобы добавить нового человека, сперва прикрепите его к учебному центру'}
                    </Typography>

                    <Button onClick={handleAttach}>
                        Прикрепить мастера производственнного обучения
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

const MastersWindow = ({groupInfo, fetchGroupInfo} : MastersWindowProps) => {

    const isAdmin = useUserStore((state) => state.isAdmin);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const handleDetach = () => {
        const masterDto = {
            master_id: groupInfo.master.master.id,
            group_id: groupInfo.id
        }

        detachMasterFromGroup(masterDto)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }


    return (
        <Box>
            <Paper sx={{...paperSx, mb: 5}}>
                <Typography variant="h4">Мастера производственнного обучения</Typography>
                {!groupInfo.master && (
                    <>
                        <Typography variant="subtitle1" sx={{mb: 1.5}} color="gray">
                            Необходимо прикрепить мастера производственнного обучения к данной группе
                        </Typography>
                        {(<Button onClick={() => {setIsDialogOpen(true);}}>
                            Выбрать мастера
                        </Button>)}
                    </>
                )}
                {groupInfo.master && (
                    <>
                        <Typography variant="subtitle1" sx={{mb: 1.5}} color="gray">
                            Мастер производственнного обучения в данной группе:
                        </Typography>
                        <Stack sx={{ml: 2, width: '400px', p: 2, borderRadius: '10px', background: '#F4F6F8'}} flexDirection="row" alignItems={'center'}>
                            <Avatar
                                sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                            >
                                {groupInfo.master.master.last_name[0]}{groupInfo.master.master.first_name[0]}
                            </Avatar>
                            <Stack sx={{width: '100%'}}>
                                <Stack flexDirection="row" alignItems="center" justifyContent="space-between"
                                       sx={{width: '100%'}}>
                                    <Typography>{groupInfo.master.master.last_name} {groupInfo.master.master.first_name}</Typography>
                                    <Typography variant="body2" color="gray">#{groupInfo.master.master.id}</Typography>
                                </Stack>
                            </Stack>
                            {groupInfo.status === 1 && (<Tooltip title={'Открепить'}>
                                <ClearIcon
                                    sx={[
                                        {ml: 5},
                                        {
                                            '&:hover': {
                                                color: 'red',
                                                cursor: 'pointer'
                                            },
                                        },
                                    ]}
                                    onClick={handleDetach}
                                />
                            </Tooltip>)}
                        </Stack>
                    </>
                )}
            </Paper>

            <DriversModal
                open={isDialogOpen}
                groupInfo={groupInfo}
                fetchGroupInfo={fetchGroupInfo}
                onClose={() => {setIsDialogOpen(false)}}
            />
        </Box>
    );
};

export default MastersWindow;