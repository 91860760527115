import {
    Box,
    Button, Checkbox,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, FormControlLabel,
    InputBase,
    InputLabel, MenuItem,
    Paper, Select,
    Stack,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import { DeleteOutline } from '@mui/icons-material';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useNavigate } from 'react-router-dom';

interface SecondStepProps {
    userForm: any,
    handleUserChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    registerLoading: boolean,
    nextStep: (step: number) => void,
    finishReg: () => void,
    registerFinished: boolean
}

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

export const SecondStep = ({userForm, handleUserChange, registerLoading, nextStep, finishReg, registerFinished} : SecondStepProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [hidden, setHidden] = useState<boolean>(true);
    const [offerAccepted, setOfferAccepted] = useState<boolean>(false);
    const [offerOpen, setOfferOpen] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setConfirmPassword(e.target.value);
    };
    const handleFinish = () => {
        finishReg();
    }
    const handleOpenOffer = () => {

        if (!userForm.iin || !userForm.first_name || !userForm.last_name || !userForm.email || !userForm.phone) {
            snackbar.errorMessage(`Заполните все необходимые поля (ИИН, Фамилия, Имя, Электронная почта, Телефон)`);
            return;
        }

        if (!userForm.password || !confirmPassword) {
            snackbar.errorMessage(`Заполните поля паролей`);
            return;
        }

        if (/^[a-zA-Z0-9$#!@%]*$/.test(userForm.password)) {
            setOfferOpen(true);
        } else if (confirmPassword !== userForm.password) {
            snackbar.errorMessage('Введеные пароли должны совпадать. Перепроверьте поля значений.')
            return;
        } else {
            snackbar.errorMessage(`Пароль может содержать только: латинские символы [a-z, A-Z], цифры (1-9), спец. символы ($,#,!,@,%)`);
            return;
        }
    }

    return (
        <Stack flexDirection={'column'}>
            <Stack flexDirection={'row'} sx={{p: '36px', background: '#FFF'}} gap={'36px'}>
                <Stack flexDirection={'column'} sx={{width: '50%'}} gap={'16px'}>
                    <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>Данные специалиста</b></Typography>

                    <InputBase
                        type={'text'}
                        placeholder={'ИИН*'}
                        value={userForm.iin}
                        name={'iin'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Фамилия*'}
                        value={userForm.last_name}
                        name={'last_name'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Имя*'}
                        value={userForm.first_name}
                        name={'first_name'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Отчество'}
                        value={userForm.patronymic}
                        name={'patronymic'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={false}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Электронная почта*'}
                        value={userForm.email}
                        name={'email'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Номер телефона*'}
                        value={userForm.phone}
                        name={'phone'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />
                </Stack>

                <Stack flexDirection={'column'} sx={{width: '50%'}} gap={'16px'}>
                    <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>Пароль</b></Typography>

                    <InputBase
                        type={hidden ? 'password' : 'text'}
                        placeholder={'Придумайте пароль'}
                        value={userForm.password}
                        name={'password'}
                        onChange={handleUserChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />
                    <InputBase
                        type={hidden ? 'password' : 'text'}
                        placeholder={'Повторите пароль'}
                        value={confirmPassword}
                        name={'password'}
                        onChange={handleChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    {hidden && <Button
                        variant={'outlined'}
                        color={'info'}
                        onClick={() => {setHidden(false)}}
                    >
                        Показать пароль
                    </Button>}
                    {!hidden && <Button
                        variant={'outlined'}
                        color={'info'}
                        onClick={() => {setHidden(true)}}
                    >
                        Скрыть пароль
                    </Button>}
                </Stack>

            </Stack>
            <Stack flexDirection={'row'} justifyContent={'center'} gap={'20px'} sx={{mt: '36px'}}>
                <Button
                    onClick={() => {nextStep(0)}}
                    color={'info'}
                    variant={'outlined'}
                    sx={{width: '207px', height: '56px'}}
                >
                    Назад
                </Button>
                <Button
                    onClick={() => {handleOpenOffer()}}
                    variant={'contained'}
                    sx={{width: '207px', height: '56px'}}
                >
                    Далее
                </Button>

            </Stack>

            <Dialog
                open={offerOpen}
                onClose={() => {setOfferOpen(false)}}
                maxWidth={'xl'}
            >
                <DialogTitle>
                    <b>Договор оферты пользования информационной системой "Курсант"</b>
                </DialogTitle>
                <DialogContent sx={{fontFamily: 'sans-serif'}}>
                    <p>Настоящая информационная система Курсант разработана в соответствии Приказа Министра внутренних дел Республики Казахстан от 2 декабря 2014 года № 862. Зарегистрированного в Министерстве юстиции Республики Казахстан 5 января 2015 года № 10056 и предназначена для регистрации групп учебных организаций по подготовке водителей транспортных средств</p>
                    <br/>
                    <p><b>1. Предмет договора</b></p>
                    <p>1.1. Настоящий договор оферты (далее - "Договор") устанавливает условия использования информационной системы "Курсант" (далее - "Система") учебными организациями по подготовке водителей транспортных средств в лице первого руководителя (далее – "Пользователь")</p>
                    <p>1.2. Система предназначена для регистрации курсантов в учебном центре по подготовке водителей транспортных средств категорий "А", "В", "С", "D", "ВЕ", "СЕ", "DЕ", "Tm", "Tb" и подкатегорий "A1", "В1", "С1", "D1", "С1Е", "D1Е"  и предоставления им необходимой информации и функционала.</p>
                    <br/>
                    <p><b>2. Права и обязанности Пользователя</b></p>
                    <p>2.1. Пользователь имеет право:</p>
                    <p>2.1.1. Зарегистрироваться в Системе и создавать учебные группы.</p>
                    <p>2.1.2. Вносить данные регистрации учебного центра, места расположения учебного центра, сведения о материально-техническом оснащении, сведения о наличии учебных транспортных средств, персональные данные руководителя учебного центра, преподавательского состава, а также сведения о категориях/подкатегориях транспортного средства, по которым будет проходить обучение.</p>
                    <p>2.1.3. Использовать функциональные возможности Системы в соответствии с ее назначением.</p>
                    <p>2.1.4. Вносить самостоятельно изменения персональных данных до момента формирования учебной группы.</p>
                    <p>2.1.5. Запрашивать корректировку персональных данных курсанта в случае выявления ошибки путем письменного обращения на электронный адрес администратора системы - admin@kursant.kz , при этом корректировка допускается не более трех знаков.</p>
                    <p>2.2. Пользователь обязуется:</p>
                    <p>2.2.1. Предоставлять достоверные данные при регистрации в Системе.</p>
                    <p>2.2.2. Соблюдать правила использования Системы и не нарушать законодательство при ее использовании.</p>
                    <p>2.2.3. Сохранять конфиденциальность своих учетных данных и не передавать их третьим лицам.</p>
                    <br/>
                    <br/>
                    <p><b>3. Оплата услуг</b></p>
                    <p>3.1. Пользователь пользуется базовым функционалом системы без оплаты.</p>
                    <p>3.1.1 При необходимости проведения дополнительных работ связанных с корректировкой персональных данных курсанта если была допущена ошибка со стороны Пользователя при условии, что группа уже зарегистрирована, пользователь обязуется оплатить комиссионное вознаграждение за работы, связанные с исправлением ошибки.</p>
                    <p>3.1.2 Пользователь оплачивает комиссионное вознаграждение в сумме 1000 (одна тысяча) тенге за каждого курсанта в чьих данных была допущена ошибка.</p>
                    <p>3.2. Расчеты между сторонами производятся посредством перечисления денежных средств на расчетный счет администрации системы.</p>
                    <br/>
                    <br/>
                    <p><b>4. Заключительные положения</b></p>
                    <p>4.1. Настоящий договор считается заключенным с момента регистрации пользователя в Системе.</p>
                    <p>4.2. Любые споры или разногласия, возникающие в связи с исполнением настоящего договора, подлежат разрешению путем переговоров между сторонами.</p>
                </DialogContent>
                <DialogActions sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 2}}>
                    <Button
                        onClick={() => {
                            setOfferAccepted(false);
                            setOfferOpen(false);
                        }}
                        variant={'contained'}
                        color={'error'}
                        disabled={registerLoading}
                    >
                        Отказаться
                    </Button>

                    {!registerLoading && (<Button
                        onClick={() => {
                            setOfferAccepted(true);
                            handleFinish();
                        }}
                        variant={'contained'}
                    >
                        Принять
                    </Button>)}

                    {registerLoading && <CircularProgress />}
                </DialogActions>
            </Dialog>

            <Dialog
                open={registerFinished}
            >
                <DialogTitle>
                    <Typography><b>Заявка на регистрацию успешно подана</b></Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack gap={'10px'}>
                        <Typography>
                            Ожидайте одобрения регистрации модерацией системы в течение рабочего дня.
                        </Typography>

                        <Typography>
                            После первичного одобрения регистрации - на вашу почту поступит письмо с ссылкой на завершение регистрации аккаунта.
                        </Typography>

                        <Typography>
                            Пожалуйста, проверяйте также папку "Спам".
                        </Typography>

                        <Button
                            onClick={() => {navigate('/')}}
                            variant={'outlined'}
                            color={'info'}
                        >
                            Перейти на главную страницу
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </Stack>
    )
};
