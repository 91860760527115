import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import React from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';

interface Props {
    placeholder?: string | null;
}

export default function SearchInput({placeholder = 'Поиск', ...props}: Props & TextFieldProps) {
    return (
        <TextField
            placeholder={placeholder || undefined}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search/>
                    </InputAdornment>
                )
            }}
            {...props}
        />
    );
}