import React, { useEffect } from 'react';
import { Avatar, Box, Chip, Divider, Link, Stack, Typography } from '@mui/material';
import { formatDate, getName } from '../../global/helpers/text';
import { DirectionsCar } from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';
import { GroupStatusChip } from './GroupStatusChip';

interface GroupInfo {
  category_id: string;
  start_date: Date | string | null;
  end_date: Date | string | null;
  passed: boolean | null;
  id: number;
  school: SchoolInfo;
  title: string;
  status: number;
}

interface SchoolInfo {
  id: number;
  title: string;
  bin: string;
  region: string;
  locality: string;
}

interface GradesInfo {
  maintenance: number | null;
  practice: number | null;
  rules: number | null;
}

interface StudentInfo {
  certificate_number: null | string;
  first_name: string;
  last_name: string;
  patronymic: string | null;
  iin: string;
  group: GroupInfo;
  grades: GradesInfo | null;
  created_at: Date;
}

interface StudentInfoBlockProps {
  studentInfo: StudentInfo;
  index: number;
}

const gradesArray = [2,3,4,5];

const StudentInfoBlock = ({studentInfo, index} : StudentInfoBlockProps) => {

  const name = getName(studentInfo);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{my: 3, p: 2, borderRadius: '10px', border: '1px solid #cfcfcf'}}
      gap={2}
    >
      <Stack
        flexDirection={'row'}
        gap={5}
      >
        <Stack
          flexDirection={'column'}
          gap={2}
        >
          <Typography variant={'subtitle2'}>Результат №{index + 1}</Typography>

          <Stack flexDirection={'row'} alignItems={'center'}>
            <Avatar
              sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
            >
              {name.partialName}
            </Avatar>
            <Typography variant={'h6'}>
              {name.fullName}
            </Typography>
          </Stack>

          <Stack flexDirection={'row'} gap={5}>
            <Stack flexDirection={'column'}>
              <Typography>
                ИИН
              </Typography>
              <Typography variant={'h6'}>
                {studentInfo.iin}
              </Typography>
            </Stack>

            <Stack flexDirection={'column'}>
              <Typography>
                Дата прикрепления
              </Typography>
              <Typography variant={'h6'}>
                {formatDate(studentInfo.created_at)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider orientation={'vertical'} flexItem={true}/>

        <Stack
          flexDirection={'column'}
          gap={2}
        >
          <Typography variant={'subtitle2'}>Информация об учебной группе</Typography>

          <Link
            href={'/groups/' + studentInfo.group.id}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            sx={{textDecoration: 'none'}}
          >
            <Avatar
              sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
            >
              <SchoolIcon fontSize="small"/>
            </Avatar>
            <Typography variant={'h6'}>
              {studentInfo.group.title}
            </Typography>
          </Link>

          <Stack flexDirection={'row'} gap={5}>
            <Stack flexDirection={'column'}>
              <Typography>
                Дата начала обучения
              </Typography>
              <Typography variant={'h6'}>
                {studentInfo.group.start_date ? formatDate(studentInfo.group.start_date) : 'Н/Д'}
              </Typography>
            </Stack>

            <Stack flexDirection={'column'}>
              <Typography>
                Дата окончания обучения
              </Typography>
              <Typography variant={'h6'}>
                {studentInfo.group.end_date ? formatDate(studentInfo.group.end_date) : 'Н/Д'}
              </Typography>
            </Stack>

            <Stack flexDirection={'column'}>
              <Typography>
                Категория ВУ
              </Typography>
              <Typography variant={'h6'}>
                {studentInfo.group.category_id}
              </Typography>
            </Stack>

            <Stack flexDirection={'column'}>
              <Typography>
                Статус группы
              </Typography>
              <Typography variant={'h6'}>
                <GroupStatusChip
                  status={studentInfo.group.status}
                  variant={'chip'}
                />
              </Typography>
            </Stack>

            <Stack flexDirection={'column'}>
              <Typography>
                Номер свидетельства
              </Typography>
              <Typography variant={'h6'}>
                {studentInfo.certificate_number ? `QZ ${studentInfo.certificate_number}` : 'Н/Д'}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

      </Stack>

      <Divider flexItem={true}/>

      <Stack
        flexDirection={'row'}
        gap={5}
      >
        <Stack
          flexDirection={'column'}
          gap={2}
        >
          <Typography variant={'subtitle2'}>Успеваемость студента</Typography>

          {!studentInfo.grades && <Typography variant={'h6'}>Нет данных об успеваемости</Typography>}

          {studentInfo.grades && (<Stack
            flexDirection={'column'}
            gap={2}
          >
            <Stack
              flexDirection={'row'}
              gap={5}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{width: '500px'}}
            >
              <Typography>
                Устройство и техническое обслуживание
              </Typography>
              <Stack flexDirection={'row'} gap={1}>
                {gradesArray.map((grade) => {
                  return (
                    <Chip
                      sx={{borderRadius: '12px'}}
                      label={grade}
                      color={studentInfo.grades?.maintenance === grade ? 'primary' : 'default'}
                    />
                  );
                })}
              </Stack>
            </Stack>

            <Stack
              flexDirection={'row'}
              gap={5}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{width: '500px'}}
            >
              <Typography>
                Правила и основы движения
              </Typography>
              <Stack flexDirection={'row'} gap={1}>
                {gradesArray.map((grade) => {
                  return (
                    <Chip
                      sx={{borderRadius: '12px'}}
                      label={grade}
                      color={studentInfo.grades?.maintenance === grade ? 'primary' : 'default'}
                    />
                  );
                })}
              </Stack>
            </Stack>

            <Stack
              flexDirection={'row'}
              gap={5}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{width: '500px'}}
            >
              <Typography>
                Практическое вождение
              </Typography>
              <Stack flexDirection={'row'} gap={1}>
                {gradesArray.map((grade) => {
                  return (
                    <Chip
                      sx={{borderRadius: '12px'}}
                      label={grade}
                      color={studentInfo.grades?.maintenance === grade ? 'primary' : 'default'}
                    />
                  );
                })}
              </Stack>
            </Stack>
          </Stack>)}

        </Stack>
      </Stack>

      <Divider flexItem={true}/>

      <Stack
        flexDirection={'row'}
        gap={5}
      >
        <Stack
          flexDirection={'column'}
          gap={2}
        >
          <Typography variant={'subtitle2'}>Информация об автошколе</Typography>

          <Link
            href={'/schools/' + studentInfo.group.school.id}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            sx={{textDecoration: 'none'}}
          >
            <Avatar
              sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
            >
              <DirectionsCar fontSize="small"/>
            </Avatar>
            <Typography variant={'h6'}>
              {studentInfo.group.school.title}
            </Typography>
          </Link>

          <Stack flexDirection={'row'} gap={5}>
            <Stack flexDirection={'column'}>
              <Typography>
                БИН
              </Typography>
              <Typography variant={'h6'}>
                {studentInfo.group.school.bin}
              </Typography>
            </Stack>

            <Stack flexDirection={'column'}>
              <Typography>
                Регион
              </Typography>
              <Typography variant={'h6'}>
                {studentInfo.group.school.region}
              </Typography>
            </Stack>

            <Stack flexDirection={'column'}>
              <Typography>
                Населенный пункт
              </Typography>
              <Typography variant={'h6'}>
                {studentInfo.group.school.locality}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};



export default StudentInfoBlock;
