import React from 'react';
import {Box, Typography} from "@mui/material";

const NoRows = () => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 5}}>
            <Typography>
                Нет данных
            </Typography>
        </Box>
    );
};

export default NoRows;