import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup, Stack, TextField,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {TestPassCategory} from "../actions/testPass.type";

interface Props {
    categoryId: TestPassCategory;
    categoryTitle?: string;
    newTest: (categoryId: TestPassCategory, promocode: string) => void,
    isLoading?: boolean
    open: boolean,
    close: () => void,
}

export const TestCreationDialog = ({   categoryId,
                                       categoryTitle,
                                       newTest,
                                       open,
                                       close,
                                       isLoading}: Props) => {
    const [promocode, setPromocode] = useState<string>('')
    const createTest = () => {
        newTest(categoryId, promocode);
        close();
        return;
    };

    return (
        <Dialog
          open={open}
          onClose={() => close()}
          maxWidth="xl"
        >
            <DialogTitle>Новый тест</DialogTitle>
            <DialogContent>
                {!!categoryTitle ?
                    <Typography>
                        Категория: {categoryTitle}
                    </Typography> :
                    <Typography>
                        Category id: {categoryId}
                    </Typography>}
                <FormGroup>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Stack direction="column" spacing={2}>
                    <TextField label="Активационный код" fullWidth sx={{mb: 2}}
                               value={promocode} onChange={(e) => setPromocode(e.currentTarget.value)}/>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <Button onClick={() => close()}>Закрыть</Button>
                        <LoadingButton onClick={createTest} variant="contained" loading={isLoading}>
                            Начать Тест
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
