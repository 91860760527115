import React, {useEffect, useState} from 'react'
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {
    Box, Button, Chip,
    CircularProgress,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useParams} from "react-router-dom";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {changeStatusByAppointmentId, getAppointmentById} from "../actions/api";
import {formatDate} from "../../global/helpers/text";
import DrivingServicesList from "../components/DrivingServicesList";
import {AppointmentStatus} from "../../Kursant/components/KursantDrivingGrid";

const StandaloneAppointments = () => {

    const {id, appointmentId} = useParams();
    const snackbar = useSnackbarStore((state) => state);
    const [appointmentInfo, appointmentInfoLoading, , fetchAppointmentInfo] = useLoadingHook(getAppointmentById);
    const [btnsDisabled, setBtnsDisabled] = useState<boolean>(false);

    useEffect(() => {
        fetchAppointmentInfo(Number(appointmentId))
    }, []);

    const handleClick = (action: string) => {
        setBtnsDisabled(true);
        if (action === 'approve') {
            changeStatusByAppointmentId(Number(appointmentId), 2)
                .then((res) => {
                    fetchAppointmentInfo(Number(appointmentId))
                    setBtnsDisabled(false);
                })
        }

        if (action === 'decline') {
            changeStatusByAppointmentId(Number(appointmentId), 3)
                .then((res) => {
                    fetchAppointmentInfo(Number(appointmentId))
                    setBtnsDisabled(false);
                })
        }

        if (action === 'cancel') {
            changeStatusByAppointmentId(Number(appointmentId), 4)
                .then((res) => {
                    fetchAppointmentInfo(Number(appointmentId))
                    setBtnsDisabled(false);
                })
        }
    }

    if (!appointmentInfo || appointmentInfoLoading) {
        return (
            <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <PageHeader />
                <Box sx={{...wrapperSx}}>
                    <CircularProgress />
                </Box>
            </Box>
        )
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Box>
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                        <Typography sx={{mt: 3}} variant={'h5'}><b>Управлениями записями на вождение</b></Typography>
                    </Stack>

                    <DrivingServicesList classes={[appointmentInfo.class]}/>

                    <Stack gap={2} sx={{my: 1}}>
                        <TextField
                            value={formatDate(appointmentInfo.date)}
                            label={'Дата записи'}
                            disabled={true}
                        />
                        <TextField
                            value={appointmentInfo.hour}
                            label={'Время записи'}
                            disabled={true}
                        />
                        <TextField
                            value={appointmentInfo.iin}
                            name={'iin'}
                            label={'ИИН'}
                            required={true}
                            disabled={true}
                        />
                        <TextField
                            value={appointmentInfo.last_name}
                            name={'last_name'}
                            label={'Фамилия'}
                            required={true}
                            disabled={true}
                        />
                        <TextField
                            value={appointmentInfo.first_name}
                            name={'first_name'}
                            label={'Имя'}
                            required={true}
                            disabled={true}
                        />
                        <TextField
                            value={appointmentInfo.patronymic}
                            name={'patronymic'}
                            label={'Отчество'}
                            required={false}
                            disabled={true}
                        />
                        <TextField
                            value={appointmentInfo.phone}
                            name={'phone'}
                            label={'Номер телефона'}
                            required={true}
                            disabled={true}
                        />
                        <TextField
                            value={appointmentInfo.email}
                            name={'email'}
                            label={'Электронная почта'}
                            required={true}
                            disabled={true}
                        />
                        <Stack flexDirection={'row'}>
                            <Typography variant={'h6'}>Статус -</Typography>
                            <Chip color={appointmentInfo.status === 2 ? 'primary' : 'default'} label={AppointmentStatus[appointmentInfo.status]} sx={{ml: 1}}/>
                        </Stack>
                        {appointmentInfo.status === 1 && (
                            <Stack flexDirection={'row'} gap={2}>
                                <Button
                                    onClick={() => {handleClick('approve')}}
                                    variant={'contained'}
                                    disabled={btnsDisabled}
                                >
                                    Подтвердить запись
                                </Button>

                                <Button
                                    onClick={() => {handleClick('decline')}}
                                    variant={'contained'}
                                    color={'error'}
                                    disabled={btnsDisabled}
                                >
                                    Отклонить запись
                                </Button>
                            </Stack>
                        )}
                        {appointmentInfo.status === 2 && (
                            <Stack flexDirection={'row'} gap={2}>
                                <Button
                                    onClick={() => {handleClick('cancel')}}
                                    variant={'contained'}
                                    color={'error'}
                                    disabled={btnsDisabled}
                                >
                                    Отменить запись
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default StandaloneAppointments