import React, {useEffect} from 'react';
import PageHeader from "../../global/components/PageHeader";
import {Box, Stack, Typography} from "@mui/material";
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useNavigate} from "react-router-dom";
import UsersList from "../components/UsersList";
import {wrapperSx} from "../../global/helpers/globalstyles";

const ChangePasswordList = () => {

    const isAdmin = useUserStore((state) => state.isAdmin);
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAdmin) {
            snackbar.errorMessage(`У вас нет доступа к данной странице`);
            navigate('/');
        }
    })

    const selectUser = (user: any) => {
        navigate(`/change-password/${user.id}`)
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />

            <Box sx={{...wrapperSx}}>

                <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                    <Typography sx={{mt: 3}} variant={'h5'}><b>Смена пароля</b></Typography>
                </Stack>

                <Box>
                    <Typography variant="h6">
                        Специалисты (пользователи)
                    </Typography>
                    <Typography variant="subtitle2">
                        Здесь отображаются все специалисты <b>всех учебных центров</b>
                    </Typography>
                </Box>

                {isAdmin && <UsersList setUser={selectUser} />}
            </Box>
        </Box>
    );
};

export default ChangePasswordList;