import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

interface DrivingServicesListProps {
    classes: any[]
}

const DrivingServicesList = ({classes} : DrivingServicesListProps) => {
    return (
        <List>
            {classes.map((service, index) => (
                <ListItem key={service.id} divider>
                    <Box
                        sx={{
                            mr: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 32,
                            height: 32,
                            borderRadius: '50%',
                            color: '#99999F', 
                            bgcolor: '#D9E8FF'
                        }}
                    >
                        {index + 1}
                    </Box>
                    <ListItemText
                        primary={
                            <Typography variant="h6">{service.title}</Typography>
                        }
                        secondary={
                            <>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    Описание: {service.description}
                                </Typography>
                                <br />
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    Длительность: {service.duration} мин.
                                </Typography>
                            </>
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default DrivingServicesList;