export function validateIIN(value: string): boolean {
    const initialIIN = value.replace(/[^0-9]/gmi, '').slice(0, 12);
    if (initialIIN.length !== 12 || initialIIN.startsWith('9999') || initialIIN.startsWith('00000000')) return false;

    const iin = initialIIN
        .slice(0, 11)
        .split('')
        .map((i) => parseInt(i));

    const n12 = parseInt(initialIIN.slice(11, 12));

    const weigths1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const weigths2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];

    const sum1 = iin.reduce((a, b, i) => {
        return a + weigths1[i] * b;
    }, 0) % 11;

    if (sum1 === 10) {
        const sum2 = iin.reduce((a, b, i) => {
            return a + weigths2[i] * b;
        }, 0) % 11;

        return sum2 === n12 ? true : false;
    } else {
        return sum1 === n12 ? true : false;
    }
}

export function validateEmail(email: string): boolean {
    // Простое регулярное выражение для валидации email, которое покрывает большинство общих случаев
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}