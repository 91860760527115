export const sxAnswerButton = {
    padding: 0,
    borderRadius: 6,
    maxWidth: 500,
    minWidth: 250,
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    transitionDuration: '.3s',
    textAlign: 'left',
    textTransform: 'none',
    transitionProperty: 'background-color, transform, color',
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.1)',
    '&:hover': {
        transform: 'scale(1.03)'
    },
    '& .text': {
        paddingLeft: '19px',
        paddingRight: '18px',
        paddingTop: '22px',
        paddingBottom: '22px',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 1.25,
    },
    '& .MuiLoadingButton-loadingIndicator': {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            width: '30px !important',
            height: '30px !important',
            color: '#2196f3'
        }
    }
}

export const sxTestsLanguageButton = {
    width: "3rem",
    height: "3rem",
    borderRadius: "100px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
}
