interface Props {
    width?: number,
    height?: number,
    color: string
}

export default function Motorcycle({width = 22, height = 22, color}: Props) {
    return <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_193_868)">
            <path
                d="M20.9525 11.75L19.04 6.485C18.8817 6.05087 18.594 5.67573 18.2158 5.41019C17.8376 5.14465 17.3871 5.00149 16.925 5H14V7.25H16.925L18.5675 11.75H13.1562L12.7513 10.625H14V8.375H8.375V10.625H10.3438L12.3912 16.25H11.6375C11.1425 13.7412 9.03875 11.885 6.40625 11.7613C3.25625 11.6038 0.5 14.225 0.5 17.375C0.5 20.525 2.975 23 6.125 23C8.8925 23 11.1312 21.0987 11.6375 18.5H16.3625C16.8575 21.0088 18.9613 22.865 21.5938 22.9887C24.7437 23.135 27.5 20.525 27.5 17.3637C27.5 14.2137 25.025 11.7387 21.875 11.7387H20.9525V11.75ZM9.2975 18.5C8.8475 19.8162 7.62125 20.75 6.125 20.75C4.235 20.75 2.75 19.265 2.75 17.375C2.75 15.485 4.235 14 6.125 14C7.62125 14 8.8475 14.9338 9.2975 16.25H6.125V18.5H9.2975ZM16.3625 16.25H14.7875L13.9663 14H17.375C16.88 14.6525 16.52 15.4062 16.3625 16.25V16.25ZM21.875 20.75C19.985 20.75 18.5 19.265 18.5 17.375C18.5 16.3287 18.9612 15.4288 19.6812 14.81L20.7613 17.78L22.8762 17.015L21.785 14.0113C21.8188 14.0113 21.8525 14 21.8863 14C23.7763 14 25.2613 15.485 25.2613 17.375C25.2613 19.265 23.765 20.75 21.875 20.75Z"
                fill={color}/>
        </g>
        <defs>
            <clipPath id="clip0_193_868">
                <rect width="27" height="27" fill={color} transform="translate(0.5 0.5)"/>
            </clipPath>
        </defs>
    </svg>;
}
