import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from '@mui/material';
import React, {ChangeEvent, useState} from 'react';
import CategorySelect from "./CategorySelect";
import {useSchoolStore} from "../../../School/store/SchoolStore";
import {CategoryDto} from "../../../School/actions/category.type";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import {createGroupApi} from "../../actions/api";

interface GroupForm {
    title: string | null;
    category: CategoryValue;
}

interface GroupDialogProps {
    open: boolean;
    close: () => void;
    updateGroups: () => void;
}

type CategoryValue = CategoryDto['id'] | null

const NewGroupDialog = ({open, close, updateGroups}: GroupDialogProps) => {

    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const [groupForm, setGroupForm] = useState<GroupForm>({
        title: '',
        category: 'A',
    });

    const [createLoading, setCreateLoading] = useState<boolean>(false);

    const changeCategory = (value: CategoryValue) => {
        setGroupForm((prev) => {
            return {
                ...prev,
                category: value
            }
        })
    };

    const changeTitle = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setGroupForm((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    };

    const handleSubmit = () => {

        if (!groupForm.category) {
            snackbar.errorMessage('Необходимо выбрать категорию');
            return;
        } else {
            const schoolId = schoolInfo.id;

            const formData = {
                title: groupForm.title ? groupForm.title : null,
                status: 1,
                category_id: groupForm.category,
                start_date: null,
                end_date: null,
                school_id: schoolId
            };

            createGroup(formData);
        }

    }

    const createGroup = (formData: any) => {
        setCreateLoading(true);
        createGroupApi(formData)
            .then((res) => {
                console.log(res);
                snackbar.successMessage(res.data.message);
                cleanForm();
                updateGroups();
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setCreateLoading(false);
            })
    }

    const cleanForm = () => {
        setGroupForm({
            title: '',
            category: 'A',
        })
    }

    return (
        <>
            <Dialog open={open} onClose={() => close()}>
                <DialogTitle>Создание группы</DialogTitle>
                <DialogContent>
                    <Typography sx={{mb: 1.5}}>
                        Создание новой группы в учебном центре <i><b>{schoolInfo.title || '...'}</b></i>
                    </Typography>
                    <TextField fullWidth name={'title'} value={groupForm.title} onChange={(e) => changeTitle(e)} label="Название"/>
                    <CategorySelect fullWidth value={groupForm.category}
                                    onChange={(value) => changeCategory(value)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => close()}>Закрыть</Button>
                    {!createLoading && (<Button
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        Создать
                    </Button>)}

                    {createLoading && (<CircularProgress />)}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NewGroupDialog;