import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputBase,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent, Stack, Typography
} from '@mui/material';
import React, { ChangeEvent, useRef } from 'react';
import { DeleteOutline } from '@mui/icons-material';

interface FirstStepProps {
    schoolForm: any;
    handleSchoolChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    registerLoading: boolean,
    uploadedImages: any[],
    uploadedFiles: any[],
    handleImageEvent: (e: ChangeEvent<HTMLInputElement>) => void,
    handleFileEvent: (e: ChangeEvent<HTMLInputElement>) => void,
    handleCategoriesChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    nextStep: (step: number) => void,
    detach: (type: string, index: number) => void,
    regionsArray: any[],
    setRegion: (region: any) => void,
    localitiesArray: any[],
    setLocality: (locality: any) => void
}

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

export const FirstStep = ({detach, schoolForm, nextStep, handleSchoolChange, registerLoading, uploadedImages,
                          uploadedFiles, handleImageEvent, handleFileEvent, handleCategoriesChange, regionsArray,
                          localitiesArray, setRegion, setLocality}: FirstStepProps) => {

    const imagesRef = useRef<HTMLInputElement>(null);
    const filesRef = useRef<HTMLInputElement>(null);

    const handleDetach = (type: string, index: number) => {

        if (type === 'image' && imagesRef.current?.files) {

            const images = imagesRef.current?.files;
            const imagesBuffer = new DataTransfer();

            for (let i = 0; i < images.length; i++) {
                if (i !== index) {
                    imagesBuffer.items.add(images[i])
                }
            }

            imagesRef.current.files = imagesBuffer.files;
        }

        if (type === 'file' && filesRef.current?.files) {

            const files = filesRef.current?.files;
            const filesBuffer = new DataTransfer();

            for (let i = 0; i < files.length; i++) {
                if (i !== index) {
                    filesBuffer.items.add(files[i])
                }
            }

            filesRef.current.files = filesBuffer.files;
        }

        detach(type, index);
    }

    const handleRegionChange = (e: SelectChangeEvent<any>) => {
        setRegion(e.target.value)
    }

    const handleLocalitiesChange = (e: SelectChangeEvent<string>) => {
        setLocality(e.target.value)
    }

    return (
        <Stack flexDirection={'column'}>
            <Stack flexDirection={'row'} sx={{p: '36px', background: '#FFF'}} gap={'36px'}>
                <Stack flexDirection={'column'} sx={{width: '50%'}} gap={'16px'}>
                    <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>Данные учебного центра</b></Typography>

                    <InputBase
                        type={'text'}
                        placeholder={'БИН*'}
                        value={schoolForm.bin}
                        name={'bin'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Полное наименование организации*'}
                        value={schoolForm.title}
                        name={'title'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Ф.И.О. Директора*'}
                        value={schoolForm.director}
                        name={'director'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Основной номер телефона*'}
                        value={schoolForm.phone1}
                        name={'phone1'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={true}
                        disabled={registerLoading}
                    />

                    <InputBase
                        type={'text'}
                        placeholder={'Дополнительный номер телефона'}
                        value={schoolForm.phone2}
                        name={'phone2'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={false}
                        disabled={registerLoading}
                    />

                    <FormControl>
                        <InputLabel id={'region-label'}>Регион</InputLabel>
                        <Select
                            labelId={'region-label'}
                            onChange={handleRegionChange}
                            name={'region'}
                            label={'Регион'}
                            sx={{
                                borderRadius: '16px',
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderWidth: 0,
                                    borderRadius: '16px',
                                }
                            }}
                            value={schoolForm.region}
                            required={true}
                        >
                            {regionsArray.map((region: any) => {
                                return (
                                    <MenuItem
                                        value={region.name_ru}
                                        key={region.name_ru}
                                    >
                                        {region.name_ru}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel id={'locality-label'}>Населенный пункт</InputLabel>
                        <Select
                            labelId={'locality-label'}
                            onChange={handleLocalitiesChange}
                            name={'locality'}
                            label={'Населенный пункт'}
                            sx={{
                                borderRadius: '16px',
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderWidth: 0,
                                    borderRadius: '16px',
                                }
                            }}
                            value={schoolForm.locality}
                            required={true}
                            disabled={localitiesArray.length === 0}
                        >
                            {localitiesArray.map((locality: any) => {
                                return (
                                    <MenuItem
                                        value={locality.name_ru}
                                        key={locality.name_ru}
                                    >
                                        {locality.name_ru}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <InputBase
                        type={'text'}
                        placeholder={'Адрес учебного центра'}
                        value={schoolForm.address}
                        name={'address'}
                        onChange={handleSchoolChange}
                        inputProps={{style: inputStyle}}
                        required={false}
                        disabled={registerLoading}
                    />
                </Stack>
                <Stack flexDirection={'column'} sx={{width: '50%'}} gap={'16px'}>

                    <Stack flexDirection={'column'}>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '5px'}} ><b>Категории учебного центра, по которым разрешено проводить обучение:</b></Typography>

                        <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('A')} disabled={registerLoading} name={'A'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'А'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('B')} disabled={registerLoading} name={'B'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'B'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('BE')} disabled={registerLoading} name={'BE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'BE'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('C')} disabled={registerLoading} name={'C'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('CE')} disabled={registerLoading} name={'CE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'CE'} />
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('D')} disabled={registerLoading} name={'D'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('DE')} disabled={registerLoading} name={'DE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'DE'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('Tb')} disabled={registerLoading} name={'Tb'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'Tb'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('Tm')} disabled={registerLoading} name={'Tm'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'Tm'} />
                        </Box>
                    </Stack>

                    <Stack flexDirection={'column'}>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '5px'}} ><b>Подкатегории учебного центра, по которым разрешено проводить обучение:</b></Typography>

                        <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('A1')} disabled={registerLoading} name={'A1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'A1'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('B1')} disabled={registerLoading} name={'B1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'B1'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('C1')} disabled={registerLoading} name={'C1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C1'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('C1E')} disabled={registerLoading} name={'C1E'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C1E'} />
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('D1')} disabled={registerLoading} name={'D1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D1'} />
                        </Box>

                        <Box sx={{display: 'flex', flexDirection: 'row', ml: '5px'}}>
                            <FormControlLabel className={'category-label'} control={<Checkbox checked={schoolForm.categories.includes('D1E')} disabled={registerLoading} name={'D1E'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D1E'} />
                        </Box>
                    </Stack>

                    <Stack flexDirection={'column'}>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>Прикрепить свидетельство о регистрации юр. лица:</b></Typography>

                        <label htmlFor='fileUpload' style={{marginTop: '10px', marginLeft: '10px'}}>
                            <p style={{display: 'inline-block'}} className='btn btn-primary'>Прикрепить
                                свидетельство о регистрации (.png, .jpg)</p>
                        </label>
                        <input id='fileUpload' type='file' multiple
                               accept='image/png, image/jpg, image/jpeg'
                               onChange={handleImageEvent}
                               disabled={registerLoading || uploadedImages.length >= 5}
                               ref={imagesRef}
                               style={{marginLeft: '10px', marginTop: '10px'}}
                        />

                        <Box>
                            {uploadedImages.length > 0 && <p style={{margin: '10px 0 0 10px'}}>Прикрепленные изображения</p>}

                            {uploadedImages.map((file, index) => {
                                return (
                                    <Box key={file.name} sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                        <img alt={file.name} src={URL.createObjectURL(file)} style={{width: '50px', height: '50px', marginLeft: '10px'}}/>
                                        <span className={'register-file'}>
                                    {index+1}. {file.name}
                                </span>
                                        <DeleteOutline
                                            sx={[
                                                {
                                                    '&:hover': {
                                                        color: 'red',
                                                        cursor: 'pointer'
                                                    },
                                                },
                                            ]}
                                            onClick={() => handleDetach('image', index)}
                                        />
                                    </Box>
                                )
                            })}
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <Stack flexDirection={'row'} justifyContent={'center'}>
                <Button
                    variant={'contained'}
                    sx={{width: '433px', height: '56px', mt: '33px'}}
                    onClick={() => {nextStep(1)}}
                >
                    Далее
                </Button>
            </Stack>
        </Stack>
    )
};