import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';

interface TimeConverterProps {
  seconds: number;
}

const TimeConverter: React.FC<TimeConverterProps> = ({ seconds }) => {
  const [convertedTime, setConvertedTime] = useState<string>('');

  useEffect(() => {
    convertSeconds(seconds);
  }, [seconds]); // Include seconds in the dependency array to re-run effect when seconds change

  const convertSeconds = (seconds: number): void => {
    // Convert seconds to minutes by rounding down
    const minutes = Math.floor(seconds / 60);
    setConvertedTime(`${minutes} минут(ы)`);
  };

  return <Chip label={convertedTime} />;
};

export default TimeConverter;
