import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import AuthPage from "./Auth/pages/AuthPage";
import SnackbarHOC from "./global/HOC/SnackbarHOC";
import RegisterPage from "./Auth/pages/RegisterPage";
import AdminPage from "./AdminPanel/pages/AdminPage";
import SchoolsList from './School/pages/SchoolsList';
import SchoolPage from './School/pages/SchoolPage';
import GroupsList from "./Group/pages/GroupsList";
import GroupPage from "./Group/pages/GroupPage";
import TransportPage from "./Transport/pages/TransportPage";
import ConfirmPage from './Confirm/pages/ConfirmPage';
import {Helmet} from "react-helmet";
import TicketsAdminPage from "./Tickets/pages/TicketsAdminPage";
import TicketsUserPage from "./Tickets/pages/TicketsUserPage";
import NewTicketPage from "./Tickets/pages/NewTicketPage";
import TicketMessages from "./Tickets/pages/TicketMessages";
import JournalPage from "./Journal/pages/JournalPage";
import BasePage from "./Base/pages/BasePage";
import NewSchoolAdmin from "./School/pages/NewSchoolAdmin";
import ChangeSchoolAdmin from "./School/pages/ChangeSchoolAdmin";
import UserPage from './Users/pages/UserPage';
import ChangePasswordList from "./Users/pages/ChangePasswordList";
import ChangeUserPassword from "./Users/pages/ChangeUserPassword";
import { createTheme, ThemeProvider } from '@mui/material';
import EmployeesPage from "./Employees/pages/EmployeesPage";
import GroupEnrollment from "./Group/pages/GroupEnrollment";
import GroupExamsProtocol from "./Group/pages/GroupExamsProtocol";
import GroupGraduation from "./Group/pages/GroupGraduation";
import StudentCertificate from "./Group/pages/StudentCertificate";
import SchoolTransactions from "./School/pages/SchoolTransactions";
import SchoolTopUpPage from "./School/pages/SchoolTopUpPage";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import SchoolDriving from "./Driving/pages/SchoolDriving";
import KursantAuthPage from "./Auth/pages/KursantAuthPage";
import KursantPage from "./Kursant/pages/KursantPage";
import ChangeUserInfo from './Users/pages/ChangeUserInfo';
import SchoolWorkers from "./School/pages/SchoolWorkers";
import StatisticsPage from './Statistic/pages/StatisticsPage';
import DrivingEmployees from "./Driving/pages/DrivingEmployees";
import DrivingSchedules from "./Driving/pages/DrivingSchedules";
import DrivingServices from "./Driving/pages/DrivingServices";
import StandaloneSchedule from './Driving/pages/StandaloneSchedule';
import StandaloneServices from './Driving/pages/StandaloneServices';
import DrivingAppointments from './Driving/pages/DrivingAppointments';
import StandaloneAppointments from './Driving/pages/StandaloneAppointments';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdminCreateNotification } from './Notifications/pages/AdminCreateNotification';
import AllNotificationsPage from './Notifications/pages/AllNotificationsPage';
import SchoolCorrections from './Corrections/pages/SchoolCorrections';
import SchoolCorrectionsInvoices from './Corrections/pages/SchoolCorrectionsInvoices';
import AdminCorrectionsPage from "./Corrections/pages/AdminCorrectionsPage";
import CorrectionInvoicePDF from './Corrections/pages/CorrectionInvoicePDF';
import AdminCorrectionsInvoicesPage from './Corrections/pages/AdminCorrectionsInvoicesPage';
import CertificateCheckPage from "./Group/pages/CertificateCheckPage";
import EmployeeEditInfo from "./Employees/pages/EmployeeEditInfo";
import {QuestionsList} from "./Test/Question/pages/QuestionsList";
import QuestionEditForm from "./Test/Question/pages/QuestionEditForm";
import QuestionOverview from "./Test/Question/pages/QuestionOverview";
import TestsOverview from "./Test/pages/TestsOverview";
import TestPage from "./Test/pages/TestPage";
import TestResults from './Test/pages/TestResults';
import BuyingPage from './Payments/pages/BuyingPage';
import SchoolLandingPage from "./Landing/pages/SchoolLandingPage";
import SchoolKursants from './School/pages/SchoolKursants';
import SchoolKursantPage from './School/pages/SchoolKursantPage';
import KursantDocsPDF from './School/pages/KursantDocsPDF';
import StudentCertificateA4 from './Group/pages/StudentCertificateA4';
import GovPage from './GovPage/pages/GovPage';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />
    },
    {
        path: "/auth",
        element: <AuthPage />
    },
    {
        path: "/auth-kursant",
        element: <KursantAuthPage />
    },
    {
        path: "/register",
        element: <RegisterPage />
    },
    {
        path: "/admin",
        element: <AdminPage />
    },
    {
        path: "/admin/notifications",
        element: <AdminCreateNotification />
    },
    {
        path: "/admin/corrections",
        element: <AdminCorrectionsPage />
    },
    {
        path: "/admin/corrections-invoices",
        element: <AdminCorrectionsInvoicesPage />
    },
    {
        path: "/notifications",
        element: <AllNotificationsPage />
    },
    {
        path: "/kursant",
        element: <KursantPage />
    },
    {
        path: "/schools",
        element: <SchoolsList />
    },
    {
        path: "/schools/:id",
        element: <SchoolPage />
    },
    {
        path: "/schools/:id/change-by-admin",
        element: <ChangeSchoolAdmin />
    },
    {
        path: "/groups",
        element: <GroupsList />
    },
    {
        path: "/groups/:id",
        element: <GroupPage />
    },
    {
        path: "/schools/:id/teachers",
        element: <EmployeesPage employee={'teachers'}/>
    },
    {
        path: "/schools/:id/drivers",
        element: <EmployeesPage employee={'drivers'}/>
    },
    {
        path: "/schools/:id/masters",
        element: <EmployeesPage employee={'masters'}/>
    },
    {
        path: "/schools/:id/employees/:employeeId",
        element: <EmployeeEditInfo />
    },
    {
        path: "/schools/:id/transport",
        element: <TransportPage />
    },
    {
        path: "/schools/:id/workers",
        element: <SchoolWorkers />
    },
    {
        path: "/schools/:id/kursants",
        element: <SchoolKursants />
    },
    {
        path: "/schools/:id/kursants/:kursantId",
        element: <SchoolKursantPage />
    },
    {
        path: "/schools/:id/kursants/:kursantId/:type",
        element: <KursantDocsPDF />
    },
    {
        path: "/schools/:id/corrections",
        element: <SchoolCorrections />
    },
    {
        path: "/schools/:id/corrections-invoices",
        element: <SchoolCorrectionsInvoices />
    },
    {
        path: "/schools/:id/corrections-invoices/:invoiceId",
        element: <CorrectionInvoicePDF />
    },
    {
        path: "/confirm/:id",
        element: <ConfirmPage />
    },
    {
        path: "/admin/tickets",
        element: <TicketsAdminPage />
    },
    {
        path: "/tickets",
        element: <TicketsUserPage />
    },
    {
        path: "/tickets/new",
        element: <NewTicketPage />
    },
    {
        path: "/tickets/:id",
        element: <TicketMessages />
    },
    {
        path: "/journal",
        element: <JournalPage />
    },
    {
        path: "/schools/:id/base",
        element: <BasePage />
    },
    {
        path: "/schools/:id/transactions",
        element: <SchoolTransactions />
    },
    {
        path: "/schools/:id/topup",
        element: <SchoolTopUpPage />
    },
    {
        path: "/schools/:id/driving",
        element: <SchoolDriving />
    },
    {
        path: "/schools/:id/driving/employees",
        element: <DrivingEmployees />
    },
    {
        path: "/schools/:id/driving/schedules",
        element: <DrivingSchedules />
    },
    {
        path: "/schools/:id/driving/schedules/:employeeId",
        element: <StandaloneSchedule />
    },
    {
        path: "/schools/:id/driving/services",
        element: <DrivingServices />
    },
    {
        path: "/schools/:id/driving/services/:employeeId",
        element: <StandaloneServices />
    },
    {
        path: "/schools/:id/driving/appointments",
        element: <DrivingAppointments />
    },
    {
        path: "/schools/:id/driving/appointments/:appointmentId",
        element: <StandaloneAppointments />
    },
    {
        path: "/schools/:id/driving",
        element: <SchoolDriving />
    },
    {
        path: "/new-school",
        element: <NewSchoolAdmin />
    },
    {
        path: "/user/:id",
        element: <UserPage />
    },
    {
        path: "/change-password",
        element: <ChangePasswordList />
    },
    {
        path: "/change-password/:id",
        element: <ChangeUserPassword />
    },
    {
        path: "/change-userinfo/:id",
        element: <ChangeUserInfo />
    },
    {
        path: "/statistic",
        element: <StatisticsPage />
    },
    {
        path: "/enrollment/:id",
        element: <GroupEnrollment />
    },
    {
        path: "/exams-protocol/:id",
        element: <GroupExamsProtocol />
    },
    {
        path: "/graduation/:id",
        element: <GroupGraduation />
    },
    {
        path: "/certificate/:id",
        element: <CertificateCheckPage />
    },
    {
        path: "/certificate/pdf/:id",
        element: <StudentCertificate />
    },
    {
        path: "/certificate/A4/pdf/:id",
        element: <StudentCertificateA4 />
    },
    {
        path: "/questions",
        element: <QuestionsList />
    },
    {
        path: "/questions/new",
        element: <QuestionEditForm />
    },
    {
        path: "/questions/:id",
        element: <QuestionEditForm />
    },
    {
        path: "/questions/demo",
        element: <QuestionOverview />
    },
    {
        path: "/questions/demo/:id",
        element: <QuestionOverview />
    },
    {
        path: "/tests",
        element: <TestsOverview />
    },
    {
        path: "/tests/:id",
        element: <TestPage />
    },
    {
        path: "/tests/results/:id",
        element: <TestResults />
    },
    {
        path: "/tests/buy",
        element: <BuyingPage />
    },
    {
        path: "/landing",
        element: <SchoolLandingPage />
    },
    {
        path: "/gov",
        element: <GovPage />
    }
]);

const theme = createTheme({
    palette: {
        primary: {
            light: '#044EBD',
            main: '#044EBD',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ec9c44',
            main: '#ec9c44',
            dark: '#d07f23',
            contrastText: '#fff',
        },
        info: {
            light: '#000000',
            main: '#000000',
            dark: '#000000',
            contrastText: '#fff'
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    height: '56px',
                    textTransform: 'none'
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    border: '1px solid #99999F',
                    borderRadius: '16px',
                    backgroundColor: '#FFFFFF',
                    '-webkit-box-shadow': 'none'
                },
                input: {
                    borderRadius: '16px',
                    backgroundColor: '#FFFFFF !important',
                    height: '56px',
                    padding: '0 10px',
                    '-webkit-background-clip': 'text',
                    transition: 'background-color 5000s ease-in-out 0s',
                    boxShadow: 'none',
                }
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        border: '0px !important'
                    },
                    '& .MuiInputBase-input': {
                        height: 'auto',
                    },
                },
            },
        },
    }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarHOC>
                <Helmet>
                  <meta name="mailru-domain" content="CWH8CeLkeUIy2vTk" />
                </Helmet>
                <RouterProvider router={router}/>
            </SnackbarHOC>
            </LocalizationProvider>
        </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
