import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

interface TestFinishConfirmationDialogProps {
  open: boolean,
  onClose: () => void,
  onFinish: () => void
}

const TestFinishConfirmationDialog = ({open, onClose, onFinish} : TestFinishConfirmationDialogProps) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        Пробный экзамен
      </DialogTitle>
      <DialogContent>
        <Stack sx={{gap: 2}}>
          <Typography>
            Вы уверены, что хотите закончить тестирование?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'contained'}
          onClick={onClose}
          color={'primary'}
        >
          Отмена
        </Button>
        <Button
          variant={'contained'}
          onClick={onFinish}
          color={'error'}
        >
          Закончить тестирование
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TestFinishConfirmationDialog;
