import React, {useEffect, useState} from 'react';
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useNavigate, useParams} from "react-router-dom";
import PageHeader from "../../global/components/PageHeader";
import {Avatar, Box, Button, CircularProgress, FormControl, FormHelperText, InputBase, Stack, TextField, Typography} from "@mui/material";
import {changePassword, changeUserInfo, getUserById} from "../actions/api";
import {getName} from "../../global/helpers/text";
import {globalInputStyle, wrapperSx} from "../../global/helpers/globalstyles";
import { UserInfoChangeDto } from '../actions/users.dto';

const ChangeUserInfo = () => {

    const currentUser = useUserStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();
    const {id} = useParams();

    const [userInfo, setUserInfo] = useState<UserInfoChangeDto>({
        id: Number(id),
        iin: '',
        first_name: '',
        last_name: '',
        patronymic: '',
        phone: '',
        email: ''
    });
    const [name, setName] = useState<any>({
        partialName: '',
        fullName: ''
    });
    const [changing, setChanging] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (isAdmin) {
            fetchUserInfo();
        } else {
            navigate('/')
            snackbar.errorMessage(`У вас нет доступа к данной странице`)
        }
    }, [])

    useEffect(() => {
        setName(getName(userInfo))
    }, [loading]);

    const fetchUserInfo = () => {
        setLoading(true);
        getUserById(Number(id))
        .then((res) => {
            console.log(res);
            setUserInfo(res.data);
        })
        .catch((err) => {
            snackbar.errorMessage(err.response.data.messaage ? err.response.data.message : 'Возникла непредвиденная ошибка');
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUserInfo((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }
    
    const handleCancel = () => {
        setChanging(false);
        fetchUserInfo();
    }

    const handleSubmit = () => {
        setLoading(true);
        setChanging(false);
        changeUserInfo(userInfo)
            .then((res) => {
                console.log(res);
                snackbar.successMessage(`Информация успешно обновлена`);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message ? err.response.data.message : 'Возникла непредвиденная ошибка');
            })
            .finally(() => {
                fetchUserInfo();
            })
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                <Typography sx={{mt: 3}} variant={'h5'}><b>Изменение информации о пользователе</b></Typography>
                <Typography variant={'h6'} sx={{mt: 1, ml: 1}}>Выбранный пользователь:</Typography>
                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    sx={{
                        p: 2,
                        borderRadius: "16px",
                        maxWidth: '500px',
                        background: '#fff'
                    }}
                >
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName ? name.partialName : ''}
                    </Avatar>
                    <Typography>{name.fullName ? name.fullName : ''} #{userInfo.id}</Typography>
                </Stack>

                <Stack
                    flexDirection={'column'}
                    sx={{
                        mt: 2,
                        p: 2,
                        borderRadius: "16px",
                        background: '#fff',
                        width: 'fit-content'
                    }}
                    gap={2}
                >
                    <FormControl>
                        <InputBase
                            type='text'
                            value={userInfo.iin}
                            name='iin'
                            sx={{minWidth: '600px'}}
                            disabled={!changing || loading}
                            onChange={handleChange}
                        />
                        <FormHelperText>
                            ИИН
                        </FormHelperText>
                    </FormControl>
                    <FormControl>
                        <InputBase
                            type='text'
                            value={userInfo.last_name}
                            name='last_name'
                            sx={{minWidth: '600px'}}
                            disabled={!changing || loading}
                            onChange={handleChange}
                        />
                        <FormHelperText>
                            Фамилия
                        </FormHelperText>
                    </FormControl>
                    <FormControl>
                        <InputBase
                            type='text'
                            value={userInfo.first_name}
                            name='first_name'
                            sx={{minWidth: '600px'}}
                            disabled={!changing || loading}
                            onChange={handleChange}
                        />
                        <FormHelperText>
                            Имя
                        </FormHelperText>
                    </FormControl>
                    <FormControl>
                        <InputBase
                            type='text'
                            value={userInfo.patronymic}
                            name='patronymic'
                            sx={{minWidth: '600px'}}
                            disabled={!changing || loading}
                            onChange={handleChange}
                        />
                        <FormHelperText>
                            Отчество
                        </FormHelperText>
                    </FormControl>
                    <FormControl>
                        <InputBase
                            type='text'
                            value={userInfo.phone}
                            name='phone'
                            sx={{minWidth: '600px'}}
                            disabled={!changing || loading}
                            onChange={handleChange}
                        />
                        <FormHelperText>
                            Номер телефона
                        </FormHelperText>
                    </FormControl>
                    <FormControl>
                        <InputBase
                            type='text'
                            value={userInfo.email}
                            name='email'
                            sx={{minWidth: '600px'}}
                            disabled={!changing || loading}
                            onChange={handleChange}
                        />
                        <FormHelperText>
                            Электронный адрес
                        </FormHelperText>
                    </FormControl>

                    {!changing && !loading && (
                        <Button variant={'contained'}
                            onClick={() => {setChanging(true)}}
                        >
                            Изменить данные
                        </Button>
                    )}
                    {changing && !loading && (
                        <Button variant='contained' color='primary'
                            onClick={() => {handleSubmit()}}
                        >
                            Сохранить изменения
                        </Button>
                    )}
                    {changing && !loading && (
                        <Button variant='contained' color='error'
                            onClick={() => {handleCancel()}}
                        >
                            Отменить изменения
                        </Button>
                    )}
                    {loading && (
                        <Stack flexDirection='row' justifyContent={'center'}><CircularProgress /></Stack>
                    )}
                </Stack>
            </Box>
        </Box>
    )
};

export default ChangeUserInfo;