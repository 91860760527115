import axiosInstance from "../../global/actions/main.api";
import {apiConfigAuthorized, BACKEND_URL} from "../../global/actions/apiVariables";
import {BaseInterface} from "./base.type";

export const getBaseInfo = (schoolId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/base/${schoolId}`);
}

export const updateBaseInfo = (info: BaseInterface) => {
    return axiosInstance.post(`${BACKEND_URL}/base/update`, info)
}