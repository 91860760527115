import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import PageHeader from '../../global/components/PageHeader';
import { wrapperSx } from '../../global/helpers/globalstyles';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import SearchByIINPage from '../components/SearchByIIN';

const GovPage = () => {

  const snackbar = useSnackbarStore((state) => state);

  // Modal
  const [openModal, setOpenModal] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');
  const [passwordTries, setPasswordTries] = useState<number>(3);

  useEffect(() => {
  }, [passwordTries]);

  const handlePassword = () => {

    if (!password) {
      snackbar.errorMessage('Введите пароль')
    }

    if (password === 'govkursant2024') {
      setOpenModal(false)
    } else {
      setPasswordTries((prev) => prev - 1)
    }
  }

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader />
      <Box sx={{...wrapperSx}}>
        <Dialog
          open={openModal}
          maxWidth={'sm'}
          fullWidth={true}
        >
          <DialogTitle>
            <Typography variant={'h6'}>
              Введите пароль
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Stack
              flexDirection={'column'}
              gap={2}
            >
              <Typography>
                У вас есть 3 попытки на ввод правильного пароля для доступа к получению информации по ИИН студентов
              </Typography>

              {passwordTries < 3 && <Typography color={'red'}>Осталось {passwordTries} количества попыток</Typography>}

              <TextField
                name={'password'}
                value={password}
                onChange={(e) => {setPassword(e.target.value)}}
                type={'password'}
                label={'Пароль'}
              />

              <Button
                variant={'contained'}
                onClick={handlePassword}
              >
                Ввести
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>

        {!openModal && <SearchByIINPage />}
      </Box>
    </Box>
  );
};

export default GovPage;