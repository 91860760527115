import React, {useEffect, useState} from 'react';
import {Avatar, Box, CircularProgress, Divider, Stack, Typography} from "@mui/material";
import PageHeader from "../../global/components/PageHeader";
import {useParams} from "react-router-dom";
import {getTicketInfo} from "../actions/api";
import {formatDateTime, getName} from "../../global/helpers/text";
import {blue, red} from "@mui/material/colors";
import MessagesHistory from "../components/MessagesHistory";
import {useUserStore} from "../../global/store/UserStore";
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import {wrapperSx} from "../../global/helpers/globalstyles";

interface TicketInfo {
    id: number,
    manager: any,
    manager_id: number,
    messages: any[],
    status: number,
    title: string,
    user: any,
    user_id: number,
    created_at: Date,
    updated_at: Date,
    closed_at: Date
}

const TicketMessages = () => {

    const {id} = useParams();
    const userInfo = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const [ticketInfo, setTicketInfo] = useState<TicketInfo | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchTicketInfo();
    // eslint-disable-next-line
    }, []);

    const fetchTicketInfo = () => {
        setLoading(true);
        getTicketInfo(Number(id))
            .then((res) => {
                setTicketInfo(res.data);
                setLoading(false);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
    };

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

            {!ticketInfo && !loading && <Typography>Ошибка. Нет данных.</Typography>}
            {!ticketInfo && loading && <CircularProgress />}
            {ticketInfo && !loading &&
                (
                    <Box sx={{display: 'flex', flexDirection: 'column', mt: 2}}>
                        <Typography color={'gray'} fontSize={'small'}>Обращение #{ticketInfo?.id}</Typography>
                        <Typography color={'gray'} fontSize={'small'}>от {formatDateTime(ticketInfo?.created_at)}</Typography>
                        <Stack sx={{display: 'flex', gap: '50px', mt: '10px'}} direction={'row'}>
                            <Box>
                                <Typography>
                                    Пользователь
                                </Typography>
                                <Stack sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <React.Fragment>
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            {getName(ticketInfo?.user).partialName}
                                        </Avatar>
                                        <Typography>{getName(ticketInfo?.user).fullName}</Typography>
                                    </React.Fragment>
                                </Stack>
                            </Box>

                            <Box>
                                <Typography>
                                    Менеджер
                                </Typography>
                                <Stack sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <React.Fragment>
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, bgcolor: red[400]}}
                                        >
                                            {ticketInfo.manager ? getName(ticketInfo?.manager).partialName : 'Х'}
                                        </Avatar>
                                        <Typography>{ticketInfo.manager ? getName(ticketInfo?.manager).fullName : 'Не назначен'}</Typography>
                                    </React.Fragment>
                                </Stack>
                            </Box>
                        </Stack>

                        <Divider style={{margin: '20px 0'}}/>

                        <Typography variant={'h4'}>
                            {ticketInfo?.title}
                        </Typography>

                        <MessagesHistory
                            ticketInfo={ticketInfo}
                            fetchTicketInfo={fetchTicketInfo}
                        />
                    </Box>
                )
            }
            </Box>
        </Box>
    );
};

export default TicketMessages;