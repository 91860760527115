import React, {useEffect, useState} from 'react';
import {Box, Button, InputBase, Typography} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {registerInputStyle} from "../../global/helpers/globalstyles";
import LoadingButton from "../../global/components/LoadingButton";
import {IRegisterUser} from "../../Auth/dto/AuthDto";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {attachNewWorker, registerNewWorker} from "../actions/api";
import {useSnackbarStore} from "../../global/store/SnackbarStore";

interface AddWorkerDialogProps {
    schoolId: number
    fetchWorkers: (schoolId: number) => void,
    onClose: () => void
}

const AddWorkerDialog = ({schoolId, fetchWorkers, onClose}: AddWorkerDialogProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const [identifier, setIdentifier] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [hidden, setHidden] = useState<boolean>(true);
    const [attachResponse, attachLoading, registerError, executeRegistration] = useLoadingHook(attachNewWorker)


    const handleUserChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;

        setIdentifier(value);
    };

    const handleWorkerSubmit = () => {
        if (!identifier) {
            snackbar.errorMessage(`Необходимо заполнить поле "Идентификатор пользователя"`)
        }
        executeRegistration({
            user_identifier: identifier,
            school_id: Number(schoolId)
        })
    };

    useEffect(() => {
        if (attachResponse) {
            cleanForm();
            fetchWorkers(schoolId);
        }
    }, [attachResponse])

    useEffect(() => {
        if (registerError) {
            console.log(registerError)
            snackbar.errorMessage(registerError.response.data.message ? registerError.response.data.message : 'Произошла ошибка')
        }
    }, [registerError])

    const cleanForm = () => {
        setConfirmPassword('');
        setIdentifier('')
    };

    return (
        <Box
            sx={{
                display: 'flex',
                width: '773px',
                height: 'auto',
                p: '32px',
                background: '#fff',
                borderRadius: '16px',
                mt: '17px',
            }}
            flexDirection={'column'}
            gap={1}
        >
            <Button
                variant={'contained'}
                onClick={onClose}
                sx={{mb: 1}}
            >
                Скрыть окно <KeyboardArrowUpIcon style={{marginLeft: '10px'}}/>
            </Button>
            <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>Регистрация нового специалиста</b></Typography>

            <InputBase
                type={'text'}
                placeholder={'Идентификатор пользователя (ID/Email/ИИН)*'}
                value={identifier}
                name={'iin'}
                onChange={handleUserChange}
                inputProps={{style: registerInputStyle}}
                required={true}
                disabled={attachLoading}
            />

            <LoadingButton
                variant={'outlined'}
                color={'primary'}
                onClick={handleWorkerSubmit}
                loading={attachLoading}
            >
                Зарегистрировать
            </LoadingButton>
        </Box>
    );
};

export default AddWorkerDialog;