import React, {useState} from 'react';
import {DataGrid, GridColDef, GridRenderCellParams, GridRowModel} from "@mui/x-data-grid";
import {Avatar, Box, Button, Chip, CircularProgress, Stack, Tooltip, Typography} from "@mui/material";
import {formatDateTime, getName} from "../../../global/helpers/text";
import {failExamApi, successExamApi} from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import { useUserStore } from '../../../global/store/UserStore';
import InfoIcon from "@mui/icons-material/Info";
import {FRONTEND_URL} from "../../../global/actions/apiVariables";
import GradesComponent from "./StudentGrades";

interface ExamsGridProps {
    rows: any[];
    fetchGroupInfo: () => Promise<void>;
    groupInfo: any;
}

const ExamsGrid = ({rows, fetchGroupInfo, groupInfo} : ExamsGridProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const [loadingIds, setLoadingIds] = useState<number[]>([]);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 90,
            sortable: true,
            renderCell: (params: GridRenderCellParams<any>) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
        },
        {
            field: 'name', headerName: 'ФИО', minWidth: 350, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'iin',
            headerName: 'ИИН',
            width: 150,
            sortable: false,
        },
        {
            field: 'grades',
            headerName: 'Оценки',
            width: 500,
            sortable: false,
            renderCell: ({row}) => {
                return loadingIds.includes(row.id)
                    ? <CircularProgress />
                    : (
                        <GradesComponent
                            schoolId={Number(groupInfo.school_id)}
                            grades={row.grades}
                            addLoadingId={addLoadingId}
                            removeLoadingId={removeLoadingId}
                            row={row}
                            groupInfo={groupInfo}
                            fetchGroupinfo={fetchGroupInfo}
                        />
                    )
            }
        },
        {
            field: 'passed',
            headerName: 'Статус сдачи',
            width: 150,
            sortable: false,
            renderCell: ({row}) => {return row.passed ? (<Chip label={'Сдал'} color={'primary'} />) : (<Chip label={'Не сдал'} color={'error'} />)}
        },
        {
            field: 'certification',
            headerName: 'Выдача сертификата',
            width: 250,
            sortable: false,
            renderCell:({row}: GridRowModel<any>) => renderCertificate(row)
        },
        {
            field: 'certification_number',
            headerName: 'Номер сертификата',
            width: 250,
            sortable: false,
            renderCell:({row}: GridRowModel<any>) => row.certificate_number ? `QZ ${row.certificate_number}` : 'Отсутствует'
        },
        {
            field: 'created_at',
            headerName: 'Дата прикрепления',
            width: 180,
            sortable: true,
            valueGetter: ({row}) => formatDateTime(row.created_at)
        },
    ];

    const addLoadingId = (rowId: number) => {
        setLoadingIds((prev) => {
            return [...prev, rowId];
        })
    }

    const removeLoadingId = (rowId: number) => {
        setLoadingIds((prev) => {
            return prev.filter((id) => {
                if (id === rowId) {
                    return false;
                } else {
                    return true;
                }
            })
        })
    }

    const renderCertificate = (row: GridRowModel<any>) => {
        if (!row.passed) {
            return <Typography style={{color: 'red'}}>Необходима успешная сдача</Typography>
        }

        if (row.passed) {
            return (
              <Stack flexDirection={'column'} alignItems={'center'} gap={1}>
                <Stack flexDirection={'row'} alignItems={'center'}>
                    <Button
                        href={`${FRONTEND_URL}/certificate/pdf/${row.id}`}
                        variant={'contained'}
                        sx={{height: 'auto'}}
                        disabled={groupInfo.status !== 4}
                    >
                        Перейти к сертификату
                    </Button>

                    {groupInfo.status !== 4 && <Tooltip title={'Для получения сертификатов необходимо закончить обучение группы'} sx={{ml: 2}}>
                        <InfoIcon/>
                    </Tooltip>}
                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Button
                    href={`${FRONTEND_URL}/certificate/A4/pdf/${row.id}`}
                    variant={'contained'}
                    sx={{height: 'auto'}}
                    disabled={groupInfo.status !== 4}
                  >
                      Печать сертификата (А4)
                  </Button>

                  {groupInfo.status !== 4 && <Tooltip title={'Для получения сертификатов необходимо закончить обучение группы'} sx={{ml: 2}}>
                      <InfoIcon/>
                  </Tooltip>}
                </Stack>
              </Stack>
            )
        }
    }

    if (groupInfo.status === 1 || groupInfo.status === 2) {
        return (
            <Box>
                <Typography variant={"h4"}>
                    Группа должна закончить обучение для возможности управления экзаменами
                </Typography>
            </Box>
        )
    }

    return (
        <Box>
            <DataGrid
                columns={columns}
                rows={rows}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 30,
                        },
                    },
                }}
                pageSizeOptions={[30]}
                disableRowSelectionOnClick={true}
                getRowHeight={() => 'auto'}
                autoHeight={true}
            />
        </Box>
    );
};

export default ExamsGrid;