import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from "../../global/actions/apiVariables";
import {TransportCreateDto} from "./transport.type";

export const getSchoolTransport = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/transport/school/${id}`);
}

export const addTransportToSchool = (formData: TransportCreateDto) => {
    return axiosInstance.post(`${BACKEND_URL}/transport/create`, formData);
}
