import React, { FormEvent, useEffect, useState } from 'react'
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import { addServiceByEmployeeId, getDrivingServicesByEmployeeId, getEmployeeWithSchedule } from '../actions/api';
import { useParams } from 'react-router-dom';
import { Avatar, Box, Button, CircularProgress, InputBase, Stack, Typography } from '@mui/material';
import PageHeader from '../../global/components/PageHeader';
import { wrapperSx } from '../../global/helpers/globalstyles';
import EmployeeCard from '../components/EmployeeCard';
import DrivingServicesList from '../components/DrivingServicesList';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ServiceCreateDto } from '../actions/driving.dto';

interface EmployeeDto {
  last_name: string,
  first_name: string
}

const StandaloneServices = () => {

    const {id, employeeId} = useParams();
    const [servicesInfo, servicesLoading, , fetchServices] = useLoadingHook(getDrivingServicesByEmployeeId);
    const [employeeInfo, setEmployeeInfo] = useState<EmployeeDto>({
      last_name: '',
      first_name: ''
    });
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [newServiceInfo, setNewServiceInfo] = useState<ServiceCreateDto>({
      title: '',
      description: '',
      duration: 0,
      employee_id: employeeId ? Number(employeeId) : null
    })
    const [addLoading, setAddLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchServices(Number(employeeId))
    }, []);

    useEffect(() => {
      if (servicesInfo) {
        servicesInfo.employee.type === 'driver' ? setEmployeeInfo({
          last_name: servicesInfo.employee.driver.last_name,
          first_name: servicesInfo.employee.driver.first_name,
        }) : setEmployeeInfo({
            last_name: servicesInfo.employee.master.last_name,
            first_name: servicesInfo.employee.master.first_name,
        });

        setNewServiceInfo((prev) => {
          return {
            ...prev,
            employee_id: Number(employeeId)
          }
        })
      }
    }, [servicesInfo]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = e.target.name;
      const value = e.target.value;

      setNewServiceInfo((prev) => {
        return {
          ...prev,
          [name]: value
        }
      })
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setAddLoading(true);
      
      addServiceByEmployeeId(newServiceInfo)
        .then((res) => {
          setAddLoading(false);
          fetchServices(Number(employeeId));
          cleanForm();;
        })
        .catch((err) => {
          console.log(err);
        })
    };

    const cleanForm = () => {
      setNewServiceInfo((prev) => {
        return {
          ...prev,
          title: '',
          description: '',
          duration: 0,
        }
      })
    }

    if (servicesLoading) {
      return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
          <PageHeader />
          <Box sx={{...wrapperSx}}>
            <CircularProgress />
          </Box>
        </Box>
      )
    };

    return (
      <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <PageHeader />
        <Box sx={{...wrapperSx}}>

            <Box>
                <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                    <Typography sx={{mt: 3}} variant={'h5'}><b>Управление услугами сотрудника</b></Typography>
                </Stack>
            </Box>

            <EmployeeCard employeeInfo={employeeInfo} />

            <Box 
              sx={{background: '#fff', borderRadius: '16px', p: 2, mt: 2}}
            >

              <Box>
                {!dialogOpen && (
                  <Button
                    variant={'contained'}
                    onClick={() => {setDialogOpen(true)}}
                    sx={{mt: '27px'}}
                  >
                    Добавить услугу <KeyboardArrowDown style={{marginLeft: '3rem'}} />
                  </Button>
                )}
                {dialogOpen && (
                  <Box>
                    <Button 
                      variant={'outlined'}
                      onClick={() => {setDialogOpen(false)}}
                      sx={{mt: '27px'}}
                    >
                      Отменить <KeyboardArrowUp style={{marginLeft: '3rem'}} />
                    </Button>
                    <form 
                      style={{display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px'}}
                      onSubmit={handleSubmit}
                    >
                      <InputBase
                          type={'text'}
                          value={newServiceInfo.title}
                          onChange={handleInputChange}
                          name={'title'}
                          sx={{pl: 2}}
                          placeholder={'Название'}
                          required={true}
                      />
                      <InputBase
                          type={'text'}
                          value={newServiceInfo.description}
                          onChange={handleInputChange}
                          name={'description'}
                          sx={{pl: 2}}
                          placeholder={'Описание'}
                          required={true}
                      />
                      <InputBase
                          type={'number'}
                          value={newServiceInfo.duration}
                          onChange={handleInputChange}
                          name={'duration'}
                          sx={{pl: 2}}
                          placeholder={'Длительность (в минутах)'}
                          required={true}
                      />
                      {!addLoading && (
                          <Button
                              variant={'contained'}
                              type={'submit'}
                              sx={{width: '227px', mt: '14px'}}
                          >
                              Добавить
                          </Button>
                      )}
                      {addLoading && (<CircularProgress/>)}
                  </form>
                </Box>
                )}
              </Box>

              <Typography sx={{mt: 3}} variant={'h6'}><b>Текущие доступные услуги:</b></Typography>
              <DrivingServicesList classes={servicesInfo?.classes ? servicesInfo.classes : []}/>
            </Box>
        </Box>
      </Box>
    )
}

export default StandaloneServices