import React, {useEffect, useState} from 'react';
import {Avatar, Box, TextField, Typography} from "@mui/material";
import {getSchoolUsers} from "../actions/api";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {getName} from "../../global/helpers/text";
import {blue} from "@mui/material/colors";
import {useUserStore} from "../../global/store/UserStore";

interface UsersListProps {
    setUser: (user: any) => void,
}

const UsersList = ({setUser} : UsersListProps) => {

    const isAdmin = useUserStore((state) => state.isAdmin);
    const [usersList, setUsersList] = useState<any[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [filteredRows, setFilteredRows] = useState<any[]>([]);

    useEffect(() => {
        applyFilter();
    }, [filter])

    const applyFilter = () => {
        if (!filter) {
            setFilteredRows(usersList);
        } else {
            const lowerCaseFilter = filter.toLowerCase();
            const filteredRows = JSON.parse(JSON.stringify(usersList)).filter((row: any) => {
                if (`${row.last_name} ${row.first_name}`.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else if (row.iin.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else {
                    return false;
                }
            });

            setFilteredRows(filteredRows);
        }
    }

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 110, sortable: true},
        {field: 'fio', headerName: 'ФИО', minWidth: 200, flex: 1, sortable: true,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {field: 'iin', headerName: 'ИИН', minWidth: 250},
        {field: 'email', headerName: 'Электронная почта', minWidth: 250},
    ];

    const NoRows = () => {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center', p: 5}}>
                <Typography>
                    Нет данных
                </Typography>
            </Box>
        )
    }


    useEffect(() => {
        if (isAdmin) {
            getSchoolUsers()
                .then((res) => {
                    setUsersList(res.data);
                    console.log(res);
                })
                .catch((err) => {
                    console.error(err.response.data.message);
                })
        } else {

        }
    }, []);

    return (
        <Box>
            <TextField
                type={'text'}
                onChange={(e) => {setFilter(e.target.value)}}
                value={filter}
                label={'Поиск'}
                sx={{mt: 1}}
            />

            <DataGrid
                columns={columns}
                rows={filter ? filteredRows : usersList}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                }}
                // loading={loading}
                autoHeight={true}
                pageSizeOptions={[20]}
                disableRowSelectionOnClick={true}
                // style={{margin: '0'}}
                slots={{
                    noRowsOverlay: NoRows
                }}
                sx={{mt: 2}}
                onRowClick={({row}) => setUser(row) }
            />
        </Box>
    );
};

export default UsersList;