import React, { useEffect, useState } from 'react';
import {Alert, Button, Step, StepContent, StepLabel, Stepper, Typography} from '@mui/material';
import {GroupDto} from "../../actions/group.type";
import {createCertificatesForGroup, finishGroupApi, getServerDate, startExamsGroupApi} from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import {convertToStandardDate, formatDate, stripTimeFromDate} from '../../../global/helpers/text';
import { useUserStore } from '../../../global/store/UserStore';

interface GroupStepperProps {
    groupInfo: GroupDto;
    addStudent: () => void;
    formGroup: () => void;
    fetchGroupInfo: () => void;
}

const GroupStepper = ({groupInfo, addStudent, formGroup, fetchGroupInfo}: GroupStepperProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const [isAvailable, setIsAvailable] = useState<boolean>(false);

    useEffect(() => {
        if (groupInfo.status === 2) {
            getServerDate()
                .then((res) => {
                    const currentDate = res.data.current_date;
                    const currentDateObj = convertToStandardDate(currentDate);
                    const endDateObj = convertToStandardDate(formatDate(groupInfo.end_date));
                    const currentDateObjStripped = stripTimeFromDate(currentDateObj);
                    const endDateObjStripped = stripTimeFromDate(endDateObj);
                    console.log(currentDateObjStripped >= endDateObjStripped);
                    setIsAvailable(currentDateObjStripped >= endDateObjStripped);
                })
        }
    }, [groupInfo]);

    const startExam = () => {
        if (groupInfo.id) {
            startExamsGroupApi(groupInfo.id)
                .then(() => {
                    snackbar.successMessage('Статус группы успешно сменен на "Экзамены"');
                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message);
                })
                .finally(() => {
                    fetchGroupInfo();
                })
        }
    }

    const finishExam = () => {
        let examsDone = true;
        const students = groupInfo.students;

        for (let i = 0; i < students.length; i++) {
            const student = students[i];
            if (student.passed === null) {
                examsDone = false;
                break;
            }
        }

        if (!examsDone) {
            snackbar.errorMessage('Необходимо выставить результат экзаменов для всех курсантов');
            return;
        }

        if (groupInfo.id) {
            finishGroupApi(groupInfo.id)
                .then(() => {
                    createCertificatesForGroup(Number(groupInfo.id))
                        .then((res) => {
                            console.log(res);
                        })
                        .catch((err) => {
                            snackbar.errorMessage(err.response.data.message ? err.response.data.message : 'Произошла ошибка');
                        })
                        .finally(() => {
                            fetchGroupInfo();
                        })
                    snackbar.successMessage('Статус группы успешно сменен на "Завершено"');

                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message);
                })
                .finally(() => {
                    fetchGroupInfo();
                })
        }
    }

    return (
        <Stepper orientation="vertical" activeStep={groupInfo.status - 1}>
            <Step>
                <StepLabel>Идёт набор</StepLabel>
                <StepContent>
                    <Typography>
                        На данном этапе идёт набор курсантов, и необходимо сформировать группу.
                    </Typography>
                    <Typography sx={{mt: 1}}>
                        Прикрепите к группе всех курсантов.
                    </Typography>
                    <Typography sx={{mt: 1}}>
                        После того как группа будет готова к запуску — нажмите кнопку «Сформировать»
                    </Typography>
                    <Alert severity="warning" sx={{mt: 1, mb: 2}}>
                        Обратите внимание! После формирования группы нельзя будет ничего изменять, в
                        том числе списки курсантов. Дата начала обучения будет соответствовать моменту формирования группы.
                    </Alert>
                    {!isAdmin && (<Button onClick={addStudent} variant={'contained'}>
                        Добавить курсанта
                    </Button>)}
                    {(<Button onClick={formGroup} color={'success'} variant={'contained'} sx={{ml: 2}}>
                        Сформировать группу
                    </Button>)}
                </StepContent>
            </Step>
            <Step>
                <StepLabel>Идёт обучение</StepLabel>
                <StepContent>
                    <Typography>
                        Группа сформирована и идёт обучение!
                    </Typography>
                    <Typography>
                        Данные зафиксированы, больше невозможно отредактировать группу и списки курсантов
                    </Typography>
                    <Typography sx={{mt: 1}}>
                        Когда обучение будет завершено, нажмите кнопку «Завершить обучение»
                    </Typography>
                    <Typography sx={{mb: 2.5}}>
                        После завершения группы можно будет провести экзаменацию курсантов.
                    </Typography>
                    {(<Button
                        onClick={startExam}
                        color={'success'}
                        variant={'contained'}
                        sx={{ml: 2}}
                        disabled={!isAvailable}
                    >
                        Завершить обучение
                    </Button>)}
                    {/* <Typography sx={{mb: 0.5}}>
                       Длительность обучения: <b>{educationDays || '—'} дней</b>
                    </Typography>
                    <Typography sx={{mb: 0.5}}>
                       Дата начала: <b>{formatDate(groupInfo?.start_date)}</b>
                    </Typography>
                    <Typography sx={{mb: 0.5}}>
                       Дата окончания: <b>{formatDate(groupInfo?.end_date)}</b>
                    </Typography>
                    <Typography sx={{mb: 2}}>
                       Осталось дней: <b>{daysLeft ? (daysLeft >= 0 ? daysLeft : 0) : '—'}</b>
                    </Typography> */}
                </StepContent>
            </Step>
            <Step>
                <StepLabel>Экзамены</StepLabel>
                <StepContent>
                    <Typography sx={{mb: 1}}>
                        Обучение завершено
                    </Typography>
                    <Typography sx={{mb: 0.5}}>
                        Теперь необходимо провести аттестацию курсантов в разделе «Экзамены»
                    </Typography>
                    <Typography sx={{mb: 2.5}}>
                        После проведения аттестации можете завершать группу
                    </Typography>
                    {(<Button onClick={finishExam} color={'warning'} variant={'contained'} sx={{ml: 2}}>
                        Закончить обучение
                    </Button>)}
                </StepContent>
            </Step>
            <Step>
                <StepLabel>Группа завершена</StepLabel>
                <StepContent>
                    <Typography>Обучение завершено, экзамены проведены! 🎉</Typography>
                </StepContent>
            </Step>
        </Stepper>
    );
};

export default GroupStepper;