import {CategoryDto} from "../../School/actions/category.type";

export interface GroupDto {
    id: number;
    school_id: number;
    category_id: string;
    status: GroupStatus;
    title: string;
    students: any[];
    start_date: Date | string | null;
    end_date: Date | string | null;
    teacher: any;
    driver: any;
    master: any;
    transport: any;
}

export enum GroupStatus {
    New = 1,
    Active = 2,
    Exams = 3,
    Finished = 4
}

export interface GroupChangeDatesDto {
    group_id: number;
    start_date: Date | string | null;
    end_date: Date | string | null;
}

export interface GroupChangeTitleDto {
    group_id: number;
    title: string;
}

type CategoryValue = CategoryDto['id'] | null

export interface GroupChangeCategoryDto {
    group_id: number;
    category_id: CategoryValue;
}

export enum GroupsFilterStatus {
    All = 0,
    New = 1,
    Active = 2,
    Exams = 3,
    Finished = 4
}

export interface GroupAttachTeacherDto {
    group_id: number;
    teacher_id: number;
}

export interface GroupAttachDriverDto {
    group_id: number;
    driver_id: number;
}

export interface GroupAttachMasterDto {
    group_id: number;
    master_id: number;
}

export interface GroupAttachTransportDto {
    group_id: number;
    car_id: number;
}