import React, { useEffect, useState } from 'react'
import { CorrectionCreateDto } from '../../actions/student.type';
import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { createCorrection } from '../../actions/api';
import { useSnackbarStore } from '../../../global/store/SnackbarStore';
import LoadingButton from '../../../global/components/LoadingButton';

interface CorrectionDialogProps {
    studentId: number;
    fetchGroupInfo: () => void;
    groupInfo: any;
    open: boolean;
    onClose: () => void;
    fetchCorrections: () => void
}

const CorrectionDialog = ({studentId, groupInfo, fetchGroupInfo, fetchCorrections, open, onClose} : CorrectionDialogProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const [correctionDto, setCorrectionDto] = useState<CorrectionCreateDto>({
        student_id: studentId,
        school_id: groupInfo.school.id,
        group_id: groupInfo.id,
        previous_last_name: '',
        previous_first_name: '',
        previous_patronymic: '',
        previous_iin: '',
        new_last_name: '',
        new_first_name: '',
        new_patronymic: '',
        new_iin: '',
        done: false,
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const desiredStudent = groupInfo.students.find((student: any) => student.id === studentId);

        setCorrectionDto((prev) => {
            return {
                ...prev,
                previous_last_name: desiredStudent.last_name,
                previous_first_name: desiredStudent.first_name,
                previous_patronymic: desiredStudent.patronymic,
                previous_iin: desiredStudent.iin,
                new_last_name: desiredStudent.last_name,
                new_first_name: desiredStudent.first_name,
                new_patronymic: desiredStudent.patronymic,
                new_iin: desiredStudent.iin,
            }
        })
    }, [studentId]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCorrectionDto((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    };

    const handleSubmit = () => {
        setLoading(true);
        createCorrection(correctionDto)
            .then(() => {
                snackbar.successMessage(`Заявка успешно подана. Отследить статус заявки можно во вкладке "Корректировки" на странице школы.`)
                onClose();
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message ? err.response.data.message : 'Произошла непредвиденная ошибка. Обратитесь в службу поддержки')
            })
            .finally(() => {
                setLoading(false);
                fetchCorrections();
            })
    };

    return (
        <Dialog
            open={open}
            onClose={() => !loading ? onClose() : snackbar.infoMessage('Дождитесь окончания подачи')}
            maxWidth={'xl'}
        >
            <DialogTitle>
                Заявка на исправление информации курсанта {correctionDto.previous_last_name} {correctionDto.previous_first_name} #{correctionDto.student_id}
            </DialogTitle>
            <DialogContent sx={{py: 2}}>

                <Stack flexDirection={'row'} alignItems={'center'} sx={{my: 2}}>
                    <InfoOutlined sx={{color: 'gray', mr: 2}}/>
                    <Stack>
                        <Typography variant={'subtitle2'}><b>Внимание!</b> Посредством данной формы можно отправить заявку на исправление информации по курсанту.</Typography>
                        <Typography variant={'subtitle2'}>Исправление информации стоит 1000 тг. согласно информации из <a href={'/UserOffer.docx'} download={true}>Договора оферты</a>.</Typography>
                        <Typography variant={'subtitle2'}>Исправить каждый из пунктов разрешается максимум на 3 символа от изначального значения.</Typography>
                    </Stack>
                </Stack>

                <Stack flexDirection={'column'} gap={3}>
                    <Stack flexDirection={'column'} gap={2}>
                        <TextField 
                            value={correctionDto.previous_iin}
                            label={"Старый ИИН"}
                            disabled={true}
                        />
                        <TextField 
                            value={correctionDto.previous_last_name}
                            label={"Старая фамилия"}
                            disabled={true}
                        />
                        <TextField 
                            value={correctionDto.previous_first_name}
                            label={"Старое имя"}
                            disabled={true}
                        />
                        <TextField 
                            value={correctionDto.previous_patronymic}
                            label={"Старое отчество"}
                            disabled={true}
                        />
                    </Stack>

                    <Stack flexDirection={'column'} gap={2}>
                        <TextField 
                            value={correctionDto.new_iin}
                            label={"Новый ИИН"}
                            name={'new_iin'}
                            onChange={handleChange}
                        />
                        <TextField 
                            value={correctionDto.new_last_name}
                            label={"Новая фамилия"}
                            name={'new_last_name'}
                            onChange={handleChange}
                        />
                        <TextField 
                            value={correctionDto.new_first_name}
                            label={"Новое имя"}
                            name={'new_first_name'}
                            onChange={handleChange}
                        />
                        <TextField 
                            value={correctionDto.new_patronymic}
                            label={"Новое отчество"}
                            name={'new_patronymic'}
                            onChange={handleChange}
                        />
                    </Stack>
                </Stack>

                <Stack gap={2} sx={{my: 2}}>
                    <LoadingButton
                        variant={'contained'}
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        Подать заявку
                    </LoadingButton>

                    <LoadingButton
                        variant={'contained'}
                        color={'error'}
                        onClick={onClose}
                        loading={loading}
                    >
                        Отмена
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default CorrectionDialog