import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from "../../global/actions/apiVariables";

// Корректировки
export const getAllCorrections = (done: string) => {
    return axiosInstance.get(`${BACKEND_URL}/corrections/all?done=${done}`);
}

export const getCorrectionsBySchoolId = (schoolId: number, done: string) => {
    return axiosInstance.get(`${BACKEND_URL}/corrections/school/${schoolId}?done=${done}`)
}

export const getCorrectionsInvoicesBySchoolId = (schoolId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/invoices/corrections/${schoolId}`)
}

export const approveCorrection = (correctionId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/corrections/execute/${correctionId}`, {});
}

// Счета на оплату
export const getAllCorrectionsInvoices = (paid: string) => {
    return axiosInstance.get(`${BACKEND_URL}/invoices/all?paid=${paid}`);
}

export const getCorrectionInvoiceById = (invoiceId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/invoices/${invoiceId}`, { responseType: 'arraybuffer' });
}

export const turnCorrInvoicePaid = (invoiceId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/invoices/corrections/paid/${invoiceId}`, {});
}

export const turnCorrInvoiceUnpaid = (invoiceId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/invoices/corrections/unpaid/${invoiceId}`, {});
}