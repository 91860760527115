import React, { useEffect, useState } from 'react';
import { useUserStore } from '../../global/store/UserStore';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import PageHeader from '../../global/components/PageHeader';
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import { findStudentByIIN } from '../actions/api';
import StudentInfoBlock from '../components/StudentInfoBlock';

const SearchByIINPage = () => {

  const snackbar = useSnackbarStore((state) => state);
  const [iinValue, setIinValue] = useState<string>('');
  const [searchResults, searchResultsLoading, , executeSearch] = useLoadingHook(findStudentByIIN);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIinValue(e.target.value);
  }

  const getStudents = async () => {
    await executeSearch(iinValue)
  }

  useEffect(() => {
    console.log(`SEARCHRESULTS`)
    console.log(searchResults)
  }, [searchResults])

  return (
    <Box>
      <Typography sx={{mt: 3}} variant={'h5'}><b>Поиск студентов по ИИН</b></Typography>
      <Typography variant={'subtitle2'}>На данной странице возможно произвести поиск студентов по ИИН</Typography>
      <Stack
        flexDirection={'row'}
        gap={2}
        sx={{my: 2}}
      >
        <TextField
          name={'iin'}
          onChange={handleChange}
          value={iinValue}
          placeholder={'ИИН'}
        />
        <Button
          variant={'contained'}
          onClick={() => {getStudents()}}
        >
          Поиск
        </Button>
      </Stack>

      <Stack flexDirection={'column'}>
        {!searchResults && !searchResultsLoading && (
          <Typography variant={'h6'}>Нет результатов</Typography>
        )}
        {searchResultsLoading && (
          <CircularProgress />
        )}
        {searchResults && searchResults.map((student: any, index: number) => {
          return <StudentInfoBlock studentInfo={student} index={index} />
        })}
      </Stack>

    </Box>
  )
}
export default SearchByIINPage