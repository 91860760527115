import {Avatar, Box, Button, IconButton, Input, Stack, SxProps, Theme, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useUserStore} from "../../global/store/UserStore";
import {formatDateTime, getName} from "../../global/helpers/text";
import {blue, red} from "@mui/material/colors";
import {Send} from "@mui/icons-material";
import { closeTicketByAdmin, sendMessage } from '../actions/api';
import {useSnackbarStore} from "../../global/store/SnackbarStore";

interface MessagesHistoryProps {
    ticketInfo: any,
    fetchTicketInfo: () => void,
}

const messageStyle: SxProps<Theme> = {
    p: 1.5,
    mb: 2.5,
    borderRadius: 3,
    maxWidth: '90%',
    backgroundColor: 'rgb(231,236,239)',
    color: 'black',
    wordWrap: 'anywhere'
};

const paperSx: SxProps<Theme> = {
    paddingX: 2.3,
    paddingY: 1.5,
    boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.30) 0px 5px 12px -4px',
    mt: 2.5,
    mb: 2.5,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
};

const MessagesHistory = ({ticketInfo, fetchTicketInfo}: MessagesHistoryProps) => {

    const userInfo = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const [message, setMessage] = useState<string>('');
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isClosing, setIsClosing] = useState<boolean>(false);

    const renderMessage = (message: any) => {
        const isRight = message.user_id === userInfo.id;

        if (!isRight) {
            return (
                <Stack key={message.id} flexDirection="row" alignItems="flex-start" justifyContent="flex-start" sx={{width: '100%'}}>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, bgcolor: message.user_id === ticketInfo.user_id ? blue[400] : red[400]}}
                    >
                        {message.user_id === ticketInfo.user_id ? getName(ticketInfo?.user).partialName : getName(ticketInfo?.manager).partialName}
                    </Avatar>
                    <Stack alignItems="flex-start" sx={{width: '100%'}}>

                        <Typography fontSize={14} sx={{color: 'gray', mb: 0.5}}>
                            {message.user_id === ticketInfo.user_id ? getName(ticketInfo?.user).fullName : getName(ticketInfo?.manager).fullName}, {formatDateTime(message.created_at)}
                        </Typography>

                        <Box sx={{...messageStyle, backgroundColor: 'rgb(231,236,239)', fontFamily: 'sans-serif'}}
                             dangerouslySetInnerHTML={{__html: message.message}}/>
                    </Stack>
                </Stack>
            )
        }

        return (
            <Stack key={message.id} flexDirection="row" alignItems="flex-start" justifyContent="flex-end" sx={{width: '100%', gap: '20px'}}>
                <Stack alignItems="flex-end" sx={{width: '100%'}}>
                    <Typography fontSize={14} sx={{color: 'gray', mb: 0.5}}>
                        {message.user_id === ticketInfo.user_id ? getName(ticketInfo?.user).fullName : getName(ticketInfo?.manager).fullName}, {formatDateTime(message.created_at)}
                    </Typography>
                    <Box sx={{...messageStyle, backgroundColor: 'rgb(196,225,244)', fontFamily: 'sans-serif'}}
                         dangerouslySetInnerHTML={{__html: message.message}}/>
                </Stack>
                <Avatar
                    sx={{mr: 1.5, fontSize: 16, bgcolor: message.user_id === ticketInfo.user_id ? blue[400] : red[400]}}
                >
                    {message.user_id === ticketInfo.user_id ? getName(ticketInfo?.user).partialName : getName(ticketInfo?.manager).partialName}
                </Avatar>
            </Stack>
        )
    }

    const send = () => {
        if (!message) {
            snackbar.errorMessage(`Невозможно послать пустое сообщение`);
            return;
        }

        setIsSending(true);
        sendMessage(ticketInfo.id, {message})
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setIsSending(false);
                fetchTicketInfo();
            })
    }

    const closeTicket = () => {
        setIsClosing(true);
        closeTicketByAdmin(ticketInfo.id)
            .then(() => {
                fetchTicketInfo();
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setIsClosing(false);
            })
    }

    return (
        <Box sx={{m: 2}}>
            {ticketInfo.messages.map((message:any) => renderMessage(message))}

            {ticketInfo.status === 10 && (<Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Typography color={'red'}>
                    Данное обращение было закрыто администратором.
                </Typography>
            </Box>)}

            <Box sx={paperSx}>
                <Input
                    placeholder="Введите сообщение"
                    disableUnderline
                    multiline
                    minRows={4}
                    sx={{flex: 1}}
                    value={message}
                    onChange={(e) => setMessage(e.currentTarget.value || '')}
                    disabled={ticketInfo.status === 10}
                />
                <IconButton
                    color="primary"
                    disabled={isSending}
                    onClick={() => send()}
                >
                    <Send/>
                </IconButton>
            </Box>

            {userInfo.isAdmin && (<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                    variant={'contained'}
                    color={'error'}
                    onClick={() => {closeTicket()}}
                    disabled={isClosing || ticketInfo.status === 10}
                >
                    Закрыть обращение
                </Button>
            </Box>)}
        </Box>
    );
};

export default MessagesHistory;