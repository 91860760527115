import React from 'react';
import {Box, Button, InputBase, Stack, Tab, Tabs, Tooltip, Typography} from '@mui/material';
import {DataGrid, GridColDef, GridRowParams} from "@mui/x-data-grid";
import {GroupStatusChip} from "./GroupStatusChip";
import {formatDate} from "../../../global/helpers/text";
import { Link, useNavigate } from 'react-router-dom';
import {PeopleOutline} from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from "@mui/icons-material/Info";
import { PaginationArgs } from '../../pages/GroupsList';

interface GridPageChangeParams {
    page: number
}

interface GroupsTableProps {
    rows: any[];
    loading: boolean;
    filter: string;
    handleFilterChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    fetchGroups: () => void;
    status: number;
    setStatus: (status: number) => void;
    pagination: PaginationArgs,
    setPage: (page: number) => void
}

const NoRows = () => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 5}}>
            <Typography>
                Нет групп с данным статусом
            </Typography>
        </Box>
    )
}

const GroupsTable = ({rows, loading, filter, handleFilterChange, fetchGroups, status, setStatus, pagination, setPage} : GroupsTableProps) => {

    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {field: 'title', headerName: 'Название', minWidth: 200, sortable: true},
        {field: 'category_id', headerName: 'Категория', width: 120, sortable: false},
        {
            field: 'stats', headerName: 'Статистика', width: 150, sortable: false,
            renderCell: ({row}) => {
                return <Stack flexDirection="row">
                    <PeopleOutline fontSize="small" sx={{color: 'gray', mr: 1}}/>
                    <Typography sx={{width: 35}} variant="body2">{row.students?.length}</Typography>
                </Stack>;
            }
        },
        {
            field: 'status', headerName: 'Статус', width: 200, sortable: false,
            renderCell: ({value}) => <GroupStatusChip status={value}/>
        },
        {
            field: 'start_date', headerName: 'Начало', width: 140, sortable: false,
            valueFormatter: ({value}) => formatDate(value)
        },
        {
            field: 'end_date', headerName: 'Конец', width: 140, sortable: false,
            valueFormatter: ({value}) => formatDate(value)
        },
        {
            field: 'new_tab', headerName: 'Действие', width: 300, sortable: false,
            renderCell: ({row}) => {
                return <Link to={`/groups/${row.id}`} target={'_blank'} onClick={(e) => {e.stopPropagation()}}>
                    <Button sx={{height: 'auto'}} variant={'contained'}>
                        Открыть в новой вкладке
                    </Button>
                </Link>;
            }        }
    ];

    const handleRowClick = (params: GridRowParams) => {
        navigate(`/groups/${params.row.id}`)
    }

    const tabs = {
        all: {id: 0, label: 'Все группы'},
        created: {id: 1, label: `Идет набор`},
        study: {id: 2, label: `Идет обучение`},
        attestation: {id: 3, label: `Экзамены`},
        finished: {id: 4, label: `Завершенные`}
    };

    return (
        <Box>
            <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} sx={{mt: 2}} gap={1}>
                <Tooltip title={'В связи с шифрованием персональных данных курсантов - поиск возможен только по полному совпадению символов, включая регистр'}>
                    <InfoIcon sx={{color: 'gray'}}/>
                </Tooltip>
                <InputBase
                    value={filter}
                    onChange={handleFilterChange}
                    placeholder={'Поиск по названию группы | данным Курсанта'}
                    sx={{width: '400px'}}
                />
            </Stack>

            <Tabs
                value={status}
                onChange={(e, newValue) => setStatus(newValue)}
                variant="scrollable"
                scrollButtons={false}
                sx={{ml: 2}}
            >
                {Object.values(tabs).map(tab => <Tab label={tab.label} key={tab.id} value={tab.id}/>)}
            </Tabs>

            <DataGrid
                columns={columns}
                rows={rows}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                            page: pagination.page,
                        },
                    },
                }}
                rowCount={pagination.count}
                onPaginationModelChange={(params: GridPageChangeParams) => {
                    setPage(params.page);
                }}
                pageSizeOptions={[20]}
                autoHeight={true}
                loading={loading}
                onRowClick={handleRowClick}
                disableRowSelectionOnClick={true}
                style={{margin: '0'}}
                slots={{
                    noRowsOverlay: NoRows
                }}
            />
        </Box>
    );
};

export default GroupsTable;