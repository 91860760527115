import { Avatar, Box, Button, Container, Divider, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import React, {useState} from 'react';
import PaymentIcon from '@mui/icons-material/Payment';
import {useNavigate} from "react-router-dom";
import {TestPassCategory} from "../actions/testPass.type";
import FlexHeader from '../../global/components/FlexHeader';
import Track from "../components/transportIcons/Track";
import Bus from "../components/transportIcons/Bus";
import Tram from "../components/transportIcons/Tram";
import Motorcycle from "../components/transportIcons/Motorcycle";
import Car from "../components/transportIcons/Car";
import {TestsList} from "../components/TestsList";
import {TestCreationDialog} from "../components/TestCreationDialog";
import {createTest} from "../actions/api";
import {useUserStore} from "../../global/store/UserStore";
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import useMediaQuery from '@mui/material/useMediaQuery';
import KursantIcon from '../../global/assets/images/KursantIcon';
import { AccessTime, Copyright, House, LocalPhone } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';

const paperSx: SxProps<Theme> = {
    padding: 2,
    boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.30) 0px 5px 12px -4px',
    mb: 2,
    borderRadius: 4
};


const testButtonSx: SxProps<Theme> = {
    background: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'row',
    p: 2,
    mb: 2,
    borderRadius: 5,
    cursor: 'pointer',
    width: '100%',
    maxWidth: '700px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
    userSelect: 'none',
    transitionProperty: 'box-shadow, transform',
    transition: '0.2s ease',
    '& .test_button_icon': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(151.09deg, #569BF5 6.29%, #4688E8 96.44%, #366AB7 96.44%)',
        borderRadius: '12px',
        mr: 2,
        width: 46,
        height: 46
    },
    '& .test_button_loading': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255,255,255,0.54)',
        borderRadius: 5,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    '&:hover': {
        boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.25) 0px 12px 24px -4px',
        transform: 'scale(1.01)'
    },
    '&:active': {
        boxShadow: 'rgba(145, 158, 146, 0.2) 0px 0px 2px 0px, rgba(145, 158, 167, 0.25) 0px 5px 18px -4px',
        transform: 'scale(1.00)'
    }
};

const tests = [
    {
        category_id: TestPassCategory.A,
        title: 'A1,  A,  B1',
        subtitle: 'мотоциклы, квадроциклы',
        icon: <Motorcycle width={24} height={24} color="white"/>
    },
    {
        category_id: TestPassCategory.B,
        title: 'B,  BE',
        subtitle: 'легковые автомобили',
        icon: <Car width={24} height={24} color="white"/>
    },
    {
        category_id: TestPassCategory.C,
        title: 'C,  C1',
        subtitle: 'грузовые автомобили',
        icon: <Track width={24} height={24} color="white"/>
    },
    {
        category_id: TestPassCategory.BC,
        title: 'BC1',
        subtitle: 'легковые и грузовые автомобили',
        icon: <Track width={24} height={24} color="white"/>
    },
    {
        category_id: TestPassCategory.D,
        title: 'D1,  D,  Tb',
        subtitle: 'автобусы',
        icon: <Bus width={24} height={24} color="white"/>
    },
    {
        category_id: TestPassCategory.CD,
        title: 'C1,  CE,   D1,  DE',
        subtitle: 'грузовики, автобусы с прицепом',
        icon: <Track width={24} height={24} color="white"/>
    },
    {
        category_id: TestPassCategory.TM,
        title: 'Tm',
        subtitle: 'Трамваи',
        icon: <Tram width={24} height={24} color="white"/>
    }
];


export default function TestsOverview() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const snackbar = useSnackbarStore((state) => state);
    const userInfo = useUserStore((state) => state);

    const [loadingCategory, setLoadingCategory] = useState<TestPassCategory | null>(null);
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [categoryId, setCategoryId] = useState<TestPassCategory>(TestPassCategory.A)
    const [categoryTitle, setCategoryTitle] = useState<string>('')
    const newTest = (category: TestPassCategory, promocode: string) => {

        const body = {
            iin: userInfo.iin,
            last_name: userInfo.last_name,
            promocode: promocode
        };

        setLoadingCategory(category);
        createTest(category, body)
            .then(r => {
                if (r.data.status === 5 || r.data.status === 2) {
                    navigate(`/tests/results/${r.data.id || r.data.test.id}`)
                } else {
                    navigate(`/tests/${r.data.id || r.data.test.id}`)
                }
                setLoadingCategory(null)
            })
            .catch(err => {
                setLoadingCategory(null)
                console.log(err)
                snackbar.errorMessage(!err.response.data.message ? 'Произошла ошибка' : err.response.data.message);
            });
    };

    const openDialog = (categoryId: number, categoryTitle: string) => {
        setCategoryId(categoryId)
        setCategoryTitle(categoryTitle)
        setOpenModal(true)

    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader/>
            <Box sx={{...wrapperSx, pt: 2}}>
                <FlexHeader sx={{mb: 5}} alignItems="center">
                    <Typography variant="h5" sx={{mt: 2}}>Пробный теоретический экзамен</Typography>
                    <Stack alignItems="center">
                        <Button variant="contained" startIcon={<PaymentIcon/>} onClick={() => navigate(`/tests/buy`)}
                                sx={{mt: 2}}>
                            Купить активационный код</Button>
                    </Stack>
                </FlexHeader>
                <Stack justifyContent="center" alignItems="center">
                    <Stack justifyContent="center" alignItems="center" sx={{width: 700, maxWidth: '95%'}}>
                        <Typography align="center" sx={{mb: 1}}>
                            Для начала пробной теоретической части экзаменов на получение водительских прав - нажмите на необходимую категорию.
                        </Typography>
                    </Stack>
                </Stack>
                <TestsList tests={tests}
                           loadingCategory={loadingCategory}
                           openDialog={openDialog}
                           sxProps={testButtonSx}
                />

                <TestCreationDialog categoryId={categoryId}
                                    categoryTitle={categoryTitle}
                                    newTest={newTest}
                                    open={openModal}
                                    close={() => setOpenModal(false)}/>
            </Box>

            <Divider />

            <Box component="footer" sx={{ bgcolor: 'background.paper', py: 2, marginTop: 'auto'}}>
                <Container maxWidth="xl">
                    <Stack flexDirection={'row'} justifyContent={'space-between'}>
                        <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <KursantIcon width={'125px'} height={'100px'} />
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Typography variant={'caption'}>
                                    <a href="/UserOffer.docx" download="Пользовательское соглашение Kursant KZ"
                                       target="_blank"
                                       rel="noreferrer"
                                       style={{
                                           color: '#ce8838',
                                           textDecoration: 'none'
                                       }}
                                    >
                                        Пользовательское соглашение
                                    </a>
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Typography variant={'caption'}>
                                    <a href="/privacy.docx" download="Политика конфиденциальности Kursant KZ"
                                       target="_blank"
                                       rel="noreferrer"
                                       style={{
                                           color: '#ce8838',
                                           textDecoration: 'none'
                                       }}
                                    >
                                        Политика конфиденциальности
                                    </a>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <LocalPhone fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'}>
                                    <b>Телефон - 8(776)-168-44-11</b>
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <AccessTime fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'}>
                                    10:00 - 18:00 (GMT +6)
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <Copyright fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'} textAlign={'right'}>
                                    ТОО "Global Driving", Kursant KZ, 2023 г.
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <InfoIcon fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'} textAlign={'right'}>
                                    БИН: 230840010667
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <House fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'} textAlign={'right'}>
                                    Астана, р. Алматы, ул. Кайрата Рыскулбекова, Дом 27/1
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
}
