import React from 'react';
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {Avatar, Box, Typography} from "@mui/material";
import {formatDate, formatDateTime, getName} from "../../global/helpers/text";
import {DirectionsCar} from "@mui/icons-material";
import {GroupStatusChip} from "../../Group/components/GroupsList/GroupStatusChip";
import NoRows from '../../global/components/NoRows';

interface KursantStudyGridProps {
    rows: any[],
    loading: boolean
}

const KursantStudyGrid = ({rows, loading} : KursantStudyGridProps) => {

    const columns: GridColDef[] = [
        // {
        //     field: 'id',
        //     headerName: 'ID',
        //     width: 90,
        //     sortable: true,
        //     renderCell: (params: GridRenderCellParams<any>) =>
        //         params.actions.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
        // },
        {
            field: 'name', headerName: 'ФИО', minWidth: 350, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'iin',
            headerName: 'ИИН',
            width: 150,
            sortable: false,
        },
        {
            field: 'group.school.title',
            headerName: 'Учебный центр',
            width: 300,
            renderCell: (params) => {
                return <React.Fragment>
                    <Avatar
                        sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2}}
                    >
                        <DirectionsCar fontSize="small"/>
                    </Avatar>
                    {params.row.group.school.title}
                </React.Fragment>;
            }
        },
        {
            field: 'group.title',
            headerName: 'Название группы',
            width: 180,
            valueGetter: ({row}) => row.group.title
        },
        {
            field: 'group.start_date',
            headerName: 'Дата начала обучения',
            width: 180,
            valueGetter: ({row}) => formatDate(row.group.start_date)
        },
        {
            field: 'group.end_date',
            headerName: 'Дата окончания',
            width: 180,
            valueGetter: ({row}) => formatDate(row.group.end_date)
        },
        {
            field: 'group.status', headerName: 'Статус группы', width: 200, sortable: false,
            renderCell: ({row}) => <GroupStatusChip status={row.group.status}/>
        },
    ];

    return (
        <Box>
            <DataGrid
                columns={columns}
                rows={rows}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                loading={loading}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick={true}
                autoHeight={true}
                slots={{
                    noRowsOverlay: NoRows
                }}
            />
        </Box>
    );
};

export default KursantStudyGrid;