import React, {useEffect} from 'react';
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {Box} from "@mui/material";
import PageInsideHeader from "../../global/components/PageInsideHeader";
import AdminCorrectionsGrid from "../components/AdminCorrectionsGrid";
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useNavigate} from "react-router-dom";

const AdminCorrectionsPage = () => {

    const currentUser = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();

    useEffect(() => {
        if (!currentUser.isAdmin) {
            snackbar.errorMessage(`У вас нет доступа к данной странице`)
            navigate('/')
        }
    }, [])

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                <PageInsideHeader text={"Корректировки курсантов"}/>

                <AdminCorrectionsGrid />
            </Box>
        </Box>
    );
};

export default AdminCorrectionsPage;