import React, {FormEvent, useEffect, useState} from 'react';
import {
    Button, CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle, FormControl, FormHelperText,
    MenuItem,
    Select, SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {formatDate} from "../../global/helpers/text";
import {Dayjs} from "dayjs";
import {useUserStore} from "../../global/store/UserStore";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {getDrivingServicesByEmployeeId} from "../../Driving/actions/api";
import {createAppointment} from "../actions/api";
import {useSnackbarStore} from "../../global/store/SnackbarStore";

interface Service {
    id: number;
    title: string;
    description: string;
    duration: string;
    employee_id: number;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

interface NewAppointmentDto {
    iin: string,
    last_name: string,
    first_name: string,
    patronymic: string,
    phone: string,
    email: string,
    date: Date | string | Dayjs,
    hour: string
}

interface FinishAppointmentProps {
    employeeId: number,
    open: boolean,
    onClose: () => void,
    selectedDate: Dayjs,
    selectedTime: string,
    fetchKursantInfo: () => void,
    mainDialogClose: () => void
}

const FinishAppointment = ({employeeId, open, onClose, selectedDate, selectedTime, fetchKursantInfo, mainDialogClose} : FinishAppointmentProps) => {


    const currentUser = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const [servicesInfo, servicesInfoLoading, , fetchServicesInfo] = useLoadingHook(getDrivingServicesByEmployeeId)
    const [newAppointmentInfo, setNewAppointmentInfo] = useState<NewAppointmentDto>({
        iin: currentUser.iin,
        last_name: currentUser.last_name,
        first_name: '',
        patronymic: '',
        phone: '',
        email: '',
        date: '',
        hour: '',
    });
    const [selectedService, setSelectedService] = useState<any>('');
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchServicesInfo(employeeId);
    }, []);

    useEffect(() => {
        console.log(servicesInfo)
    }, [servicesInfo]);

    const handleAppointmentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewAppointmentInfo((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    };

    const handleServiceSelect = (e: SelectChangeEvent<any>) => {
        setSelectedService(e.target.value)
    }

    const cleanForm = () => {
        setNewAppointmentInfo({
            iin: currentUser.iin,
            last_name: currentUser.last_name,
            first_name: '',
            patronymic: '',
            phone: '',
            email: '',
            date: '',
            hour: '',
        })
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!selectedService) {
            snackbar.errorMessage(`Необходимо выбрать услугу для записи.`);
            return;
        }

        if (!currentUser.iin || !currentUser.last_name) {
            snackbar.errorMessage(`Некорректный ИИН/Фамилия. Перезайдите в качестве курсанта`);
            return;
        }

        setSubmitLoading(true);
        createAppointment({
            ...newAppointmentInfo,
            date: selectedDate.toDate(),
            hour: selectedTime,
            class_id: selectedService,
            employee_id: employeeId
        })
            .then((res) => {
                cleanForm();
                fetchKursantInfo();
                onClose();
                mainDialogClose();
            })
            .catch((err) => {
                snackbar.errorMessage(`Возникла ошибка при попытке записаться на вождение. Попробуйте заново.`)
            })
            .finally(() => {
                setSubmitLoading(false);
            })
    };

    return (
        <Dialog open={open} maxWidth={'xl'}>
            <DialogTitle>
                Совершить запись на вождение
            </DialogTitle>
            <DialogContent>
                <form
                    style={{display: 'flex', flexDirection: 'column', gap: '10px', margin: '10px 0'}}
                    onSubmit={handleSubmit}
                >
                    <Stack sx={{flexDirection: 'column', gap: '10px'}}>
                        {servicesInfo && (
                            <FormControl>
                                <Select
                                    value={selectedService}
                                    onChange={handleServiceSelect}
                                >
                                    {servicesInfo.classes.map((service: Service) => {
                                        return <MenuItem value={service.id}>{service.title} {service.duration} мин.</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>
                                    Выберите услугу, предоставляемую специалистом
                                </FormHelperText>
                            </FormControl>
                        )}
                        <TextField
                            value={formatDate(selectedDate?.toDate())}
                            label={'Дата записи'}
                            disabled={true}
                            helperText="Данное поле заполняется автоматически"
                        />
                        <TextField
                            value={selectedTime}
                            label={'Время записи'}
                            disabled={true}
                            helperText="Данное поле заполняется автоматически"
                        />
                        <TextField
                            value={newAppointmentInfo.iin}
                            name={'iin'}
                            label={'ИИН'}
                            required={true}
                            disabled={true}
                            helperText="Данное поле заполняется автоматически"
                        />
                        <TextField
                            value={newAppointmentInfo.last_name}
                            name={'last_name'}
                            label={'Фамилия'}
                            required={true}
                            disabled={true}
                            helperText="Данное поле заполняется автоматически"
                        />
                    </Stack>
                    <TextField
                        value={newAppointmentInfo.first_name}
                        name={'first_name'}
                        label={'Имя'}
                        required={true}
                        helperText="Например: Аян"
                        onChange={handleAppointmentChange}
                    />
                    <TextField
                        value={newAppointmentInfo.patronymic}
                        name={'patronymic'}
                        label={'Отчество'}
                        required={false}
                        helperText="Например: Қайратұлы"
                        onChange={handleAppointmentChange}
                    />
                    <TextField
                        value={newAppointmentInfo.phone}
                        name={'phone'}
                        label={'Номер телефона'}
                        required={true}
                        helperText="Например: +7776xxxxxxx"
                        onChange={handleAppointmentChange}
                    />
                    <TextField
                        value={newAppointmentInfo.email}
                        name={'email'}
                        label={'Электронная почта'}
                        required={true}
                        helperText="Например: xyz@gmail.com"
                        onChange={handleAppointmentChange}
                    />
                    {!submitLoading && <Stack gap={'10px'}>
                        <Button
                            type={'submit'}
                            variant={'contained'}
                        >
                            Записаться
                        </Button>
                        <Button
                            variant={'contained'}
                            color={'error'}
                            onClick={() => {
                                onClose()
                            }}
                        >
                            Отменить
                        </Button>
                    </Stack>}
                    {submitLoading && (
                        <Stack flexDirection={'row'} justifyContent={'center'}>
                            <CircularProgress />
                        </Stack>
                    )}
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default FinishAppointment;