import React, {ChangeEvent, useEffect, useState} from 'react';
import {
    Box, Stack, Tab, Tabs, Typography
} from '@mui/material';

import '../styles/RegisterPage.scss';
import {IRegisterSchool, IRegisterUser} from "../dto/AuthDto";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import { getLocalitiesByRegionId, getRegions, register, registerByAdmin } from '../actions/api';
import PageHeader from "../../global/components/PageHeader";
import { FirstStep } from '../components/FirstStep';
import { SecondStep } from '../components/SecondStep';
import { useUserStore } from '../../global/store/UserStore';
import { Link } from 'react-router-dom';



const FirstStepIcon = () => {
    return <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="14" fill="#044EBD"/>
        <path d="M13.6601 20.3599V8.65986L14.6861 9.72186H10.9961V7.75986H16.0001V20.3599H13.6601Z" fill="white"/>
    </svg>
}

const SecondStepGray = () => {
    return <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="14" fill="#E8E8E8"/>
        <path d="M9.89172 21V19.434L14.8957 14.682C15.3157 14.286 15.6277 13.938 15.8317 13.638C16.0357 13.338 16.1677 13.062 16.2277 12.81C16.2997 12.546 16.3357 12.3 16.3357 12.072C16.3357 11.496 16.1377 11.052 15.7417 10.74C15.3457 10.416 14.7637 10.254 13.9957 10.254C13.3837 10.254 12.8257 10.362 12.3217 10.578C11.8297 10.794 11.4037 11.124 11.0437 11.568L9.40572 10.308C9.89772 9.648 10.5577 9.138 11.3857 8.778C12.2257 8.406 13.1617 8.22 14.1937 8.22C15.1057 8.22 15.8977 8.37 16.5697 8.67C17.2537 8.958 17.7757 9.372 18.1357 9.912C18.5077 10.452 18.6937 11.094 18.6937 11.838C18.6937 12.246 18.6397 12.654 18.5317 13.062C18.4237 13.458 18.2197 13.878 17.9197 14.322C17.6197 14.766 17.1817 15.264 16.6057 15.816L12.3037 19.902L11.8177 19.02H19.1797V21H9.89172Z" fill="#99999F"/>
    </svg>
}

const SecondStepBlue = () => {
    return <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="14" fill="#044EBD"/>
        <path d="M9.67154 20.3599V18.7939L14.6755 14.0419C15.0955 13.6459 15.4075 13.2979 15.6115 12.9979C15.8155 12.6979 15.9475 12.4219 16.0075 12.1699C16.0795 11.9059 16.1155 11.6599 16.1155 11.4319C16.1155 10.8559 15.9175 10.4119 15.5215 10.0999C15.1255 9.77586 14.5435 9.61386 13.7755 9.61386C13.1635 9.61386 12.6055 9.72186 12.1015 9.93786C11.6095 10.1539 11.1835 10.4839 10.8235 10.9279L9.18554 9.66786C9.67754 9.00786 10.3375 8.49786 11.1655 8.13786C12.0055 7.76586 12.9415 7.57986 13.9735 7.57986C14.8855 7.57986 15.6775 7.72986 16.3495 8.02986C17.0335 8.31786 17.5555 8.73186 17.9155 9.27186C18.2875 9.81186 18.4735 10.4539 18.4735 11.1979C18.4735 11.6059 18.4195 12.0139 18.3115 12.4219C18.2035 12.8179 17.9995 13.2379 17.6995 13.6819C17.3995 14.1259 16.9615 14.6239 16.3855 15.1759L12.0835 19.2619L11.5975 18.3799H18.9595V20.3599H9.67154Z" fill="white"/>
    </svg>
}

const RegisterPage = () => {

    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);
    const [schoolForm, setSchoolForm] = useState<IRegisterSchool>({
        bin: '',
        title: '',
        director: '',
        region: '',
        locality: '',
        address: '',
        phone1: '',
        phone2: '',
        categories: []
    });
    const [userForm, setUserForm] = useState<IRegisterUser>({
        iin: '',
        gender: 0,
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        phone: '',
        address: '',
        password: ''
    })
    const [uploadedImages, setUploadedImages] = useState<File[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [registerLoading, setRegisterLoading] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [regionsArray, setRegionsArray] = useState<any[]>([]);
    const [localitiesArray, setLocalitiesArray] = useState<any[]>([]);
    const [registerFinished, setRegisterFinished] = useState<boolean>(false);

    useEffect(() => {
        getRegions()
            .then((res) => {
                setRegionsArray(res.data);
            })
    }, [])

    const handleSchoolChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setSchoolForm((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleUserChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setUserForm((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleCategoriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const name = e.target.name;

        if (checked) {
            const newCategories = schoolForm.categories.concat(name);

            setSchoolForm((prev) => {
                return {
                    ...prev,
                    categories: newCategories
                }
            })
        }

        if (!checked) {
            const isIncluded = schoolForm.categories.includes(name);

            if (isIncluded) {
                const newCategories : string[] = [];

                for (let i = 0; i < schoolForm.categories.length; i++) {
                    const element = schoolForm.categories[i];

                    if (element !== name) {
                        newCategories.push(element);
                    }
                }

                setSchoolForm((prev) => {
                    return {
                        ...prev,
                        categories: newCategories
                    }
                })
            }
        }
    }

    const handleFileEvent = (e: ChangeEvent<HTMLInputElement>) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleAttachedFiles(chosenFiles);
    }

    const handleAttachedFiles = (files: File[]) => {
        const uploaded = [...uploadedFiles];

        if ((files.length + uploaded.length) > 5) {
            snackbar.errorMessage('Максимально допустимое количество файлов - 5');
            return;
        }
        files.forEach((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            }
        })
        setUploadedFiles(uploaded);
    }

    const handleImageEvent = (e: ChangeEvent<HTMLInputElement>) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleAttachedImages(chosenFiles);
    }

    const handleAttachedImages = (files: File[]) => {
        const uploaded = [...uploadedImages];

        if ((files.length + uploaded.length) > 5) {
            snackbar.errorMessage('Максимально допустимое количество файлов - 5');
            return;
        }
        files.forEach((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            }
        })
        setUploadedImages(uploaded);
    }

    const finishReg = () => {
        const formData = new FormData();

        const schoolInfo = {
            ...schoolForm,
        }

        const userInfo = {
            ...userForm
        }

        if (schoolInfo.locality === 'Другое (вписать в адрес)') {
            formData.set('school', JSON.stringify({...schoolInfo, locality: ''}));
        } else {
            formData.set('school', JSON.stringify(schoolInfo));
        }
        formData.set('user', JSON.stringify(userInfo));

        if (uploadedImages.length > 0) {
            for (let i = 0; i < uploadedImages.length; i++) {
                const image = uploadedImages[i];
                formData.append('img', image)
            }
        }

        if (uploadedFiles.length > 0) {
            for (let i = 0; i < uploadedFiles.length; i++) {
                const file = uploadedFiles[i];
                formData.append('file', file)
            }
        }

        if (currentUser.iin && currentUser.isAdmin) {
            setRegisterLoading(true);
            registerByAdmin(formData)
                .then(() => {
                    snackbar.infoMessage(`Автошкола ${schoolInfo.title} зарегистрирована. Аккаунт активен.`);
                    setCurrentStep(0);
                    cleanInputs();
                    setRegisterFinished(true);
                })
                .catch((e) => {
                    const message = e.response.data.message;
                    snackbar.errorMessage(message);
                })
                .finally(() => {
                    setRegisterLoading(false);
                    return;
                })
        } else {
            setRegisterLoading(true);
            register(formData)
                .then(() => {
                    snackbar.infoMessage('Заявка на регистрацию успешно подана. Ожидайте ответа по электронной почте.')
                    setCurrentStep(0);
                    cleanInputs();
                    setRegisterFinished(true);
                })
                .catch((e) => {
                    const message = e.response.data.message;
                    snackbar.errorMessage(message);
                })
                .finally(() => {
                    setRegisterLoading(false);
                })
        }
    };

    const cleanInputs = () => {
        setSchoolForm({
            bin: '',
            title: '',
            director: '',
            region: '',
            locality: '',
            address: '',
            phone1: '',
            phone2: '',
            categories: []
        });

        setUserForm({
            iin: '',
            gender: 0,
            first_name: '',
            last_name: '',
            patronymic: '',
            email: '',
            phone: '',
            address: '',
            password: ''
        })

        setUploadedImages([]);
        setUploadedFiles([]);
    }

    const pageObj = [
        {
            path: '/',
            title: 'Главная'
        },
        {
            path: '/register',
            title: 'Регистрация'
        }
    ];

    const checkSchoolForm = () => {

        if (schoolForm.region === 'Алматы' || schoolForm.region === 'Астана' || schoolForm.region === 'Шымкент') {

        } else {
            if (!schoolForm.locality) {
                return false;
            }
        }

        return !(!schoolForm.bin || !schoolForm.phone1 || !schoolForm.title || !schoolForm.director || schoolForm.categories.length < 1 || !schoolForm.region || !schoolForm.address);
    }

    const checkUserForm = () => {
        return !(!userForm.iin || !userForm.email || !userForm.phone || !userForm.last_name || !userForm.first_name);
    }

    const nextStep = (step: number) => {

        if (step === 1) {
            const isSchoolValid = checkSchoolForm();
            if (!isSchoolValid) {
                snackbar.errorMessage('Необходимо заполнить все поля и выбрать категории');
                return;
            }
        }

        if (step === 2) {
            const isUserValid = checkUserForm();
            if (!isUserValid) {
                snackbar.errorMessage('Необходимо заполнить все поля');
                return;
            }
        }

        setCurrentStep(step);
    }

    const setRegion = (chosenRegion: any) => {
        setSchoolForm((prev) => {
            return {
                ...prev,
                region: chosenRegion
            }
        })

        let regionId: number;

        regionsArray.map((region) => {
            if (region.name_ru === chosenRegion) {
                regionId = region.id
                getLocalitiesByRegionId(regionId)
                    .then((res) => {
                        if (regionId === 1 || regionId === 2 || regionId === 3) {
                            setLocalitiesArray(res.data);
                        } else {
                            setLocalitiesArray([
                                ...res.data,
                                {
                                    id: 50000,
                                    name_ru: 'Другое (вписать в адрес)'
                                }
                            ])
                        }
                    })
            }
        })
    }

    const setLocality = (chosenLocality: any) => {

        if (chosenLocality === 'Другое (вписать в адрес)') {
            setSchoolForm((prev) => {
                return {
                    ...prev,
                    locality: 'Другое (вписать в адрес)'
                }
            })
            return;
        }

        setSchoolForm((prev) => {
            return {
                ...prev,
                locality: chosenLocality
            }
        })
    }

    const detach = (type: string, index: number) => {
        if (type === 'image') {
            const filteredImages = uploadedImages.filter((image, arrIndex) => {
                return index !== arrIndex;
            });
            setUploadedImages(filteredImages);
        }

        if (type === 'file') {
            const filteredFiles = uploadedFiles.filter((file, arrIndex) => {
                return index !== arrIndex;
            });
            setUploadedFiles(filteredFiles);
        }
    }

    return (
        <Box className={'register'} id={'register'} sx={{p: 5, background: '#F8F9FB'}}>
            <PageHeader pages={pageObj} />
            <Stack flexDirection={'row'} justifyContent={'center'} sx={{mt: '21px'}}>
                {currentStep === 0 && (<Typography variant={'h6'}>Регистрация учебного центра</Typography>)}
                {currentStep === 1 && (<Typography variant={'h6'}>Регистрация специалиста учебного центра ответственного за регистрацию групп в ИС Курсант</Typography>)}
                {currentStep === 2 && (<Typography variant={'h6'}>Создание пароля</Typography>)}
            </Stack>

            <Stack>
                <Link to={'/landing'} style={{width: 'fit-content', color: 'grey'}}>Вернуться на главную страницу</Link>
            </Stack>
            <Tabs
                sx={{width: '100%'}}
                variant={'fullWidth'}
                value={currentStep}
            >
                <Tab label={<FirstStepIcon />} value={0} sx={{width: '50%'}} disabled={true}/>
                <Tab label={currentStep === 1 ? <SecondStepBlue /> : <SecondStepGray />} value={1} sx={{width: '50%'}} disabled={true}/>
            </Tabs>
            {currentStep === 0 && (
                <FirstStep
                    schoolForm={schoolForm}
                    handleSchoolChange={handleSchoolChange}
                    registerLoading={registerLoading}
                    uploadedImages={uploadedImages}
                    uploadedFiles={uploadedFiles}
                    handleImageEvent={handleImageEvent}
                    handleFileEvent={handleFileEvent}
                    handleCategoriesChange={handleCategoriesChange}
                    nextStep={nextStep}
                    detach={detach}
                    regionsArray={regionsArray}
                    setRegion={setRegion}
                    localitiesArray={localitiesArray}
                    setLocality={setLocality}
                />
            )}
            {currentStep === 1 && (
                <SecondStep
                    userForm={userForm}
                    handleUserChange={handleUserChange}
                    registerLoading={registerLoading}
                    nextStep={nextStep}
                    finishReg={finishReg}
                    registerFinished={registerFinished}
                />
            )}
        </Box>
    );
};

export default RegisterPage;