import React, {useEffect, useState} from 'react';
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {
    Avatar,
    Box, Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography
} from "@mui/material";
import {buyDrivingModuleOneMonth, checkIsDrivingAvailable, getProductInfo} from "../actions/api";
import {useNavigate, useParams} from "react-router-dom";
import {DirectionsCar} from "@mui/icons-material";
import {getSchoolBalance, getSchoolInfo} from "../../School/actions/api";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {Card} from "../../School/components/Card";
import StorageIcon from "@mui/icons-material/Storage";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {useUserStore} from "../../global/store/UserStore";
import {formatDate} from "../../global/helpers/text";

const SchoolDriving = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);

    const [isAvailable, availableLoading, , fetchAvailable] = useLoadingHook(checkIsDrivingAvailable);
    const [balance, balanceLoading, , fetchBalance] = useLoadingHook(getSchoolBalance);
    const [schoolInfo, , , fetchSchoolInfo] = useLoadingHook(getSchoolInfo);
    const [productInfo, , , fetchProductInfo] = useLoadingHook(getProductInfo);

    useEffect(() => {
        fetchInfo();
    }, []);

    const fetchInfo = () => {
        fetchAvailable({school_id: Number(id), product_id: 1});
        fetchBalance(Number(id));
        fetchSchoolInfo(Number(id));
        fetchProductInfo(1);
    }

    const NotAvailablePage = () => {

        const [dialogOpen, setDialogOpen] = useState<boolean>(false);
        const [buyLoading, setBuyLoading] = useState<boolean>(false);
        const hasEnoughMoney = Number(balance?.balance) >= Number(productInfo?.price);
        const difference = Number(productInfo?.price) - Number(balance?.balance);

        const handleBuy = () => {
            setBuyLoading(true);
            buyDrivingModuleOneMonth({
                school_id: schoolInfo?.id,
                product_id: productInfo?.id,
                user_id: currentUser.id ? currentUser.id : null
            })
                .then((res) => {
                    setBuyLoading(false);
                    snackbar.successMessage(`Подписка успешно оформлена. Поздравляем с покупкой!`);
                    fetchInfo();
                })
        }

        return (
            <>
                <Stack
                    flexDirection={'column'}
                    sx={{
                        mt: '17px',
                        p: '32px',
                        background: '#fff',
                        width: 'fit-content',
                        borderRadius: '16px'
                    }}
                    gap={'32px'}
                >
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
                        >
                            <DirectionsCar fontSize="medium"/>
                        </Avatar>
                        <Typography variant={'h4'}>
                            {schoolInfo?.title}
                        </Typography>
                    </Stack>

                    <Box>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '24px'}}><b>Баланс</b></Typography>
                        <Stack flexDirection={'row'} alignItems='center'>
                            <Typography sx={{mb: 0.5, verticalAlign: 'center'}}>
                                Текущий баланс - {balanceLoading ? <CircularProgress /> : (<b>{balance?.balance} тг.</b>)}
                            </Typography>
                        </Stack>
                    </Box>

                    {!hasEnoughMoney && (
                        <Box>
                            <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '24px'}}><b>Недостаточно средств для покупки</b></Typography>
                            <Stack flexDirection={'column'} gap={'10px'}>
                                <Typography sx={{mb: 0.5, verticalAlign: 'center'}}>
                                    Вам не хватает <b style={{color: 'red'}}>{difference ? difference : '0'}</b> тг. Для приобретения модуля - <b>пополните баланс.</b>
                                </Typography>
                                <Button
                                    onClick={() => {navigate(`/schools/${schoolInfo.id}/topup`)}}
                                    variant={'outlined'}
                                    sx={{maxWidth: '200px'}}
                                >
                                    Перейти к пополнению
                                </Button>
                            </Stack>
                        </Box>
                    )}
                    {hasEnoughMoney && (
                        <Box>
                            <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '24px'}}><b>Приобретение подписки</b></Typography>
                            <Stack flexDirection={'column'} gap={'10px'}>
                                <Typography sx={{mb: 0.5, verticalAlign: 'center'}}>
                                    Стоимость модуля: <b>{productInfo?.price} тг.</b> за {productInfo?.period} день.
                                </Typography>
                                <Typography sx={{mb: 0.5, verticalAlign: 'center'}}>
                                    Вам хватает средств на приобретение подписки на модуль.
                                </Typography>
                                <Button
                                    onClick={() => {setDialogOpen(true)}}
                                    variant={'outlined'}
                                >
                                    Перейти к приобретению
                                </Button>
                            </Stack>
                        </Box>
                    )}
                </Stack>

                <Box sx={{mt: 4}}>
                    <Typography variant="h4">База данных</Typography>
                    <Stack flexDirection="row" flexWrap="wrap" sx={{mt: 1}}>

                        <Card
                            title="Записи на вождение"
                            icon={<StorageIcon/>}
                            footer={[]}
                            onClick={() => {snackbar.errorMessage(`Необходимо приобрести модуль "Записи на вождение" для доступа к данному функционалу`)}}
                        />

                        <Card
                            title="Сотрудники"
                            icon={<PersonAddIcon/>}
                            footer={[]}
                            onClick={() => {snackbar.errorMessage(`Необходимо приобрести модуль "Записи на вождение" для доступа к данному функционалу`)}}
                        />
                    </Stack>
                </Box>

                <Dialog
                    open={dialogOpen}
                    maxWidth={'xl'}
                    sx={{p: 10}}
                    onClose={() => {setDialogOpen(false)}}
                >
                    <DialogTitle>
                       Подтверждение приобретения модуля
                    </DialogTitle>
                    <DialogContent>
                        <Typography>Ваш текущий баланс: <b>{balance?.balance} тг.</b></Typography>
                        <Typography>У вас спишется <b style={{color: 'red'}}>-{productInfo?.price}</b> тг. и будет предоставлен доступ к модулю "Запись на вождение" на 1 месяц.</Typography>
                    </DialogContent>
                    {!buyLoading && <DialogActions sx={{flexDirection: 'column', gap: '10px'}}>
                        <Typography><b>Пожалуйста, подтвердите приобретение</b></Typography>
                        <Stack flexDirection={'row'} gap={'20px'} sx={{pb: 2}}>
                            <Button
                                variant={'outlined'}
                                sx={{minWidth: '200px', height: 'auto'}}
                                onClick={() => {
                                    setDialogOpen(false)
                                }}
                            >
                                Отмена
                            </Button>
                            <Button
                                variant={'contained'}
                                sx={{minWidth: '200px', height: 'auto'}}
                                onClick={() => {
                                    handleBuy()
                                }}
                            >
                                Подтвердить покупку
                            </Button>
                        </Stack>
                    </DialogActions>}
                    {buyLoading && <DialogActions sx={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', pb: 2}}><CircularProgress /></DialogActions>}
                </Dialog>
            </>
        )
    }

    const AvailablePage = () => {
        return (
            <>
                <Stack
                    flexDirection={'column'}
                    sx={{
                        mt: '17px',
                        p: '32px',
                        background: '#fff',
                        width: 'fit-content',
                        borderRadius: '16px'
                    }}
                    gap={'32px'}
                >
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
                        >
                            <DirectionsCar fontSize="medium"/>
                        </Avatar>
                        <Typography variant={'h4'}>
                            {schoolInfo?.title}
                        </Typography>
                    </Stack>

                    <Box>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '24px'}}><b>Баланс</b></Typography>
                        <Stack flexDirection={'row'} alignItems='center'>
                            <Typography sx={{mb: 0.5, verticalAlign: 'center'}}>
                                Текущий баланс - {balanceLoading ? <CircularProgress /> : (<b>{balance?.balance} тг.</b>)}
                            </Typography>
                        </Stack>
                    </Box>

                    <Box>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '24px'}}><b>Доступ</b></Typography>
                        <Stack flexDirection={'column'} gap={'10px'}>
                            <Typography sx={{mb: 0.5, verticalAlign: 'center'}}>
                                Продукт приобретен. Доступ предоставлен до <b>{formatDate(isAvailable?.product?.expired_at)} г.</b>
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>

                <Box sx={{mt: 4}}>
                    <Typography variant="h4">База данных</Typography>
                    <Stack flexDirection="row" flexWrap="wrap" sx={{mt: 1}}>

                        <Card
                            title="Сотрудники"
                            icon={<PersonAddIcon/>}
                            footer={[]}
                            onClick={() => {navigate(`/schools/${id}/driving/employees`)}}
                        />

                        <Card
                            title="Расписание"
                            icon={<PersonAddIcon/>}
                            footer={[]}
                            onClick={() => {navigate(`/schools/${id}/driving/schedules`)}}
                        />

                        <Card
                            title="Услуги"
                            icon={<PersonAddIcon/>}
                            footer={[]}
                            onClick={() => {navigate(`/schools/${id}/driving/services`)}}
                        />

                        <Card
                            title="Записи на вождение"
                            icon={<StorageIcon/>}
                            footer={[]}
                            onClick={() => {navigate(`/schools/${id}/driving/appointments`)}}
                        />
                    </Stack>
                </Box>
            </>
        )
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Typography sx={{mt: 3}} variant={'h5'}><b>Запись на вождение</b></Typography>

                {!availableLoading && !isAvailable?.status && (<NotAvailablePage />)}
                {!availableLoading && isAvailable?.status && (<AvailablePage />)}
                {availableLoading && <CircularProgress sx={{mt: 2}}/>}
            </Box>
        </Box>
    );
};

export default SchoolDriving;