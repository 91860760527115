import React from 'react';
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {Avatar, Box, Chip, Typography} from "@mui/material";
import {formatDate, formatDateTime, getName} from "../../global/helpers/text";
import {DirectionsCar} from "@mui/icons-material";
import {GroupStatusChip} from "../../Group/components/GroupsList/GroupStatusChip";
import NoRows from '../../global/components/NoRows';

interface KursantDrivingGridProps {
    rows: any[],
    loading: boolean
}

export enum AppointmentStatus {
    'Ожидает подтверждения' = 1,
    'Подтверждено' = 2,
    'Отклонено' = 3,
    'Отменено' = 4
}

const KursantDrivingGrid = ({rows, loading} : KursantDrivingGridProps) => {

    const columns: GridColDef[] = [
        {
            field: 'name', headerName: 'ФИО', minWidth: 350, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'locality', headerName: 'Город/НП',
            width: 300,
            renderCell: ({row}) => `${row.school.region}, ${row.school.locality}`
        },
        {
            field: 'date', headerName: 'Дата записи',
            width: 200,
            renderCell: ({row}) => formatDate(row.date)
        },
        {
            field: 'hour', headerName: 'Время записи',
            width: 200,
            renderCell: ({row}) => row.hour
        },
        {
            field: 'school.title', headerName: 'Название автошколы',
            width: 200,
            renderCell: ({row}) => row.school.title
        },
        {
            field: 'employee.name', headerName: 'ФИО инструктора',
            width: 250,
            renderCell: ({row}) => {
                const name = row.employee.type === 'driver' ? getName(row.employee.driver) : getName(row.employee.master);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {
            field: 'status', headerName: 'Статус записи',
            width: 200,
            renderCell: ({row}) => {
                return <Chip label={AppointmentStatus[row.status]}/>
            }
        },
    ];

    return (
        <Box>
            <DataGrid
                columns={columns}
                rows={rows}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                loading={loading}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick={true}
                autoHeight={true}
                slots={{
                    noRowsOverlay: NoRows
                }}
            />
        </Box>
    );
};

export default KursantDrivingGrid;