import React, {useEffect, useState} from 'react';
import {Avatar, Box, Button, CircularProgress, InputBase, Stack, TextField, Typography} from "@mui/material";
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {useUserStore} from "../../global/store/UserStore";
import {useNavigate, useParams} from "react-router-dom";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useSchoolStore} from "../store/SchoolStore";
import {getSchoolBalance, getSchoolInfo, submitTopUp} from "../actions/api";
import {DirectionsCar} from "@mui/icons-material";
import useLoadingHook from "../../global/hooks/UseLoadingHook";

export type TransactionType = 'topup' | 'buy' | 'withdraw';

export interface TransactionStartDto {
    type: TransactionType,
    school_id: number,
    user_id: null | number,
    product_id: null | number,
    status?: string,
    amount: number,
}

const SchoolTransactions = () => {

    const navigate = useNavigate();
    const {id} = useParams();
    const currentUser = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const currentSchool = useSchoolStore((state) => state.storeSchoolInfo);
    const [balance, balanceLoading, , fetchBalance] = useLoadingHook(getSchoolBalance);
    const [schoolInfo, schoolInfoLoading, , fetchSchoolInfo] = useLoadingHook(getSchoolInfo);
    const [balanceForm, setBalanceForm] = useState<TransactionStartDto>({
        type: "topup",
        school_id: Number(id),
        user_id: Number(currentUser.id),
        product_id: null,
        amount: 0,
    });
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);


    useEffect(() => {
        fetchInfo();
    }, []);

    const fetchInfo = () => {
        fetchSchoolInfo(Number(id));
        fetchBalance(Number(id));
    }

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        if (Number(e.target.value) > 1000000) {
            snackbar.errorMessage(`Введенная сумма не может быть больше 1.000.000 тенге`)
            return;
        }

        if (Number(e.target.value) < 0) {
            snackbar.errorMessage(`Введенная сумма не может быть отрицательной или менее 10 тенге`)
            return;
        }

        setBalanceForm((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = () => {

        if (balanceForm.amount > 1000000 || balanceForm.amount < 10) {
            snackbar.errorMessage(`Введенная сумма не соответствует позволяемому диапазону`);
            return;
        }

        setSubmitLoading(true);
        submitTopUp(balanceForm)
            .then((res) => {
                console.log(res);
                snackbar.successMessage(`Переход на страницу оплаты`)
                cleanForm();
                setSubmitLoading(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const cleanForm = () => {
        setBalanceForm((prev) => {
            return {
                ...prev,
                amount: 0
            }
        })
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Typography sx={{mt: 3}} variant={'h5'}><b>Пополнение баланса</b></Typography>


                <Stack
                    flexDirection={'column'}
                    sx={{
                        mt: '17px',
                        p: '32px',
                        background: '#fff',
                        width: 'fit-content',
                        borderRadius: '16px'
                    }}
                    gap={'32px'}
                >
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
                        >
                            <DirectionsCar fontSize="medium"/>
                        </Avatar>
                        <Typography variant={'h4'}>
                            {schoolInfo?.title}
                        </Typography>
                    </Stack>

                    <Box>
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px', mb: '24px'}} ><b>Баланс</b></Typography>
                        <Stack flexDirection={'row'} alignItems='center'>
                            <Typography sx={{mb: 0.5, verticalAlign: 'center', display: 'flex', alignItems: 'center'}}>
                                Текущий баланс: {balanceLoading ? <CircularProgress style={{marginLeft: '10px', width: '25px', height: '25px'}}/> : (<b style={{marginLeft: '10px'}}>{balance?.balance} тг.</b>)}
                            </Typography>
                        </Stack>

                    </Box>

                    <Stack
                        flexDirection={'column'}
                        minWidth={'500px'}
                        gap={'20px'}
                    >
                        <Typography sx={{background: '#F8F9FB', p: '8px 16px'}} ><b>Пополнить баланс</b></Typography>
                        <TextField
                            type={'number'}
                            placeholder={'Сумма пополнения, в тг.'}
                            value={balanceForm.amount}
                            inputProps={{
                                min: 0,
                                max: 1000000
                            }}
                            onChange={handleAmountChange}
                            name={'amount'}
                            helperText="Введите сумму от 10 до 1 000 000 тенге"
                        />

                        {!submitLoading && <Button
                            onClick={() => {
                                handleSubmit()
                            }}
                            variant='contained'
                            disabled={schoolInfoLoading || balanceLoading}
                        >
                            Перейти к оплате
                        </Button>}
                        {submitLoading && <CircularProgress />}
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
};

export default SchoolTransactions;