import { create } from 'zustand'
import {createJSONStorage, persist} from "zustand/middleware";

interface SchoolState {
    id: number | null;
    title: string;
    storeSchoolInfo: (id: number, title: string) => void
}

export const useSchoolStore = create<SchoolState>()(
    persist(
        (set) => ({
            id: null,
            title: '',
            storeSchoolInfo: (id: number, title: string) => set((state) => ({id, title}))
        }),
        {
            name: 'school-storage',
            storage: createJSONStorage(() => localStorage),
        }
    )
)