import React, { useEffect } from 'react';
import useLoadingHook from '../../global/hooks/UseLoadingHook';
import { getTestResults } from '../actions/api';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Chip, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useUserStore } from '../../global/store/UserStore';
import PageHeader from '../../global/components/PageHeader';
import { wrapperSx } from '../../global/helpers/globalstyles';
import { TestPassCategory } from '../../Kursant/components/KursantPromocodeGrid';
import TimeConverter from '../components/TimeConverter';

const TestResults = () => {

  const navigate = useNavigate();
  const params = useParams();
  const testPassId = Number(params.id);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const snackbar = useSnackbarStore((state) => state);
  const userStore = useUserStore((state) => state);


  const [testData, testDataLoading, testDataError, fetchTestData] = useLoadingHook(getTestResults);

  useEffect(() => {
    fetchTestData(testPassId, {iin: userStore.iin, last_name: userStore.last_name});
  }, [])

  useEffect(() => {
    if (testDataError) {
      snackbar.errorMessage(testDataError.response.data.message ? testDataError.response.data.message : 'Ошибка');
      navigate('/tests');
    }
  }, [testDataError]);

  useEffect(() => {
    if (testData) console.log(testData);
  }, [testData]);

  if (testDataLoading || !testData) {
    return (
      <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <PageHeader/>
        <Box sx={{...wrapperSx, py: 3, background: '#F8F9FB'}} display={'flex'} flexDirection={'column'} gap={2}>
          <CircularProgress />
        </Box>
      </Box>
    )
  }

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader/>
      <Box sx={{...wrapperSx, py: 3, background: '#F8F9FB'}} display={'flex'} flexDirection={'column'} gap={2}>
        <Stack>
          <Typography
            variant={'h5'}
          >
            Результаты пробного тестирования
          </Typography>
        </Stack>
        <Stack gap={1}>

          <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
            <Typography>
              ИИН тестируемого:
            </Typography>
            <Chip label={testData.promocode.testee_iin} />
          </Stack>

          <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
            <Typography>
              Фамилия тестируемого:
            </Typography>
            <Chip label={testData.promocode.testee_last_name} />
          </Stack>

          <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
            <Typography>
              Категория:
            </Typography>
            <Chip label={TestPassCategory[testData.test.category]} />
          </Stack>

          <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
            <Typography>
              Количество правильных ответов:
            </Typography>
            <Chip label={testData.test.correct_count} />
          </Stack>

          <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
            <Typography>
              Количество неправильных ответов:
            </Typography>
            <Chip label={testData.test.incorrect_count} />
          </Stack>

          <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
            <Typography>
              Затраченное время:
            </Typography>
            <TimeConverter seconds={(new Date(testData.test.end_at).getTime() - new Date(testData.test.start_at).getTime())/1000} />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default TestResults;
