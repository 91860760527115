import React from 'react';
import { Chip, Stack, Grid } from '@mui/material';

interface MiniScheduleProps {
    days: string[],
    hours: string[]
}

const MiniSchedule = ({ days, hours } : MiniScheduleProps) => {
    const daysOfWeek = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
    const daysLabels = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];
    const hoursRange = Array.from({ length: 15 }, (_, i) => (i + 7).toString().padStart(2, '0') + ":00");
  
    return (
      <Stack gap={1} sx={{p: 1}}>
        <Stack direction="row" spacing={1}>
          {daysOfWeek.map((day, index) => (
            <Chip
              key={day}
              label={daysLabels[index]}
              style={{
                backgroundColor: days.includes(day) ? '#044EBD' : 'transparent',
                borderColor: 'gray',
                color: days.includes(day) ? 'white' : 'gray',
                border: '1px solid',
                borderRadius: '8px'
              }}
            />
          ))}
        </Stack>

        <Grid container spacing={1}>
            {hoursRange.map(hour => (
            <Grid item key={hour}>
                <Chip
                label={hour}
                style={{
                    backgroundColor: hours.includes(hour) ? '#044EBD' : 'transparent',
                    borderColor: 'gray',
                    color: hours.includes(hour) ? 'white' : 'gray',
                    border: '1px solid',
                    borderRadius: '8px',
                }}
                />
            </Grid>
            ))}
        </Grid>
      </Stack>
    );
  };
  
  export default MiniSchedule;
  