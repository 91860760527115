export const globalInputStyle = {
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: 0,
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
    }
};

export const wrapperSx = {
    pl: 5,
    pr: 5,
    background: '#F8F9FB',
    flexGrow: 1
};

export const registerInputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };