import React, {useEffect, useState} from 'react';
import {Box, Stack, Typography} from "@mui/material";
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useNavigate} from "react-router-dom";
import PageHeader from "../../global/components/PageHeader";
import { getAllTickets, getTicketsByStatus } from '../actions/api';
import TicketsAdminGrid from "../components/TicketsAdminGrid";
import {wrapperSx} from "../../global/helpers/globalstyles";

export enum TicketStatus {
    Created = 1,
    AnsweredByAdmin = 2,
    AnsweredByUser = 3,
    Closed = 10
};

const TicketsAdminPage = () => {

    const isAdmin = useUserStore((state) => state.isAdmin);
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [tickets, setTickets] = useState<any[]>([]);
    const [tab, setTab] = useState<TicketStatus>(0);

    useEffect(() => {
        if (!isAdmin) {
            snackbar.errorMessage('У вас нет права доступа к данной странице');
            navigate('/');
        } else {
            fetchTickets(0);
        }
    // eslint-disable-next-line
    }, []);

    const fetchTickets = (status: number) => {
        setLoading(true);

        if (status === 0) {
            getAllTickets()
                .then((res) => {
                    console.log(res);
                    setTickets(res.data);
                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                })
        }

        if (status !== 0) {
            getTicketsByStatus(status)
                .then((res) => {
                    console.log(res);
                    setTickets(res.data);
                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                })
        }

    };

    const changeTab = (newTab: number) => {
        setTab(newTab);
        fetchTickets(newTab);
    }

    const goToTicket = (ticketId: number) => {
        navigate(`/admin/tickets/${ticketId}`);
    }

    const pageObj = [
        {
            path: '/',
            title: 'Главная'
        },
        {
            path: '/admin/tickets',
            title: 'Служба поддержки'
        }
    ];

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>

                <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                    <Typography sx={{mt: 3}} variant={'h5'}><b>Служба поддержки</b></Typography>
                </Stack>

                <TicketsAdminGrid
                    openAppeal={goToTicket}
                    rows={tickets}
                    setTab={changeTab}
                    tab={tab}
                    loading={loading}
                />
            </Box>
        </Box>
    );
};

export default TicketsAdminPage;