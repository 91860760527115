import React, {useEffect, useState} from 'react';
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {
    Avatar,
    Box,
    Button,
    Stack,
    Typography
} from "@mui/material";
import {useParams} from "react-router-dom";
import {useSchoolStore} from "../store/SchoolStore";
import {useUserStore} from "../../global/store/UserStore";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {getUsersBySchoolId} from "../actions/api";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {getName} from "../../global/helpers/text";
import NoRows from "../../global/components/NoRows";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import RegisterWorkerDialog from "../components/RegisterWorkerDialog";
import AddWorkerDialog from "../components/AddWorkerDialog";

const SchoolWorkers = () => {

    const {id} = useParams();
    const schoolInfo = useSchoolStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const snackbar = useSnackbarStore((state) => state);
    const [registerDialogOpen, setRegisterDialogOpen] = useState<boolean>(false);
    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
    const [workersList, workersLoading, , fetchWorkers] = useLoadingHook(getUsersBySchoolId);

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 110, sortable: true},
        {field: 'fio', headerName: 'ФИО', minWidth: 360, flex: 1, sortable: true,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName}
                </React.Fragment>;
            }
        },
        {field: 'email', headerName: 'Электронный адрес', minWidth: 250},
        {field: 'role', headerName: 'Должность', minWidth: 250, renderCell: ({row}) => row.roles[0].role === 'manager' ? 'Главный специалист' : 'Специалист'},
    ];


    useEffect(() => {
        fetchWorkers(Number(id));
    }, []);

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader/>

            <Box sx={{...wrapperSx}}>
                <Stack flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} sx={{mb: 2}}>
                    <Typography sx={{mt: 3}} variant={'h5'}><b>Сотрудники учебного центра (пользователи)</b></Typography>
                    <Typography variant="subtitle1">
                        Здесь отображаются все специалисты и сотрудники учебного центра <i><b>{schoolInfo.title}</b></i>
                    </Typography>
                    {!isAdmin && !registerDialogOpen && (
                        <Button
                            variant={'contained'}
                            onClick={() => {setRegisterDialogOpen(true)}}
                            sx={{mt: '27px'}}
                        >
                            Регистрация нового специалиста <KeyboardArrowDownIcon style={{marginLeft: '3rem'}}/>
                        </Button>
                    )}

                    {registerDialogOpen && (
                        <RegisterWorkerDialog
                            schoolId={Number(id)}
                            fetchWorkers={fetchWorkers}
                            onClose={() => setRegisterDialogOpen(false)}
                        />
                    )}

                    {!isAdmin && !addDialogOpen && (
                        <Button
                            variant={'contained'}
                            onClick={() => {setAddDialogOpen(true)}}
                            sx={{mt: '27px'}}
                        >
                            Добавить существующего пользователя <KeyboardArrowDownIcon style={{marginLeft: '3rem'}}/>
                        </Button>
                    )}

                    {addDialogOpen && (
                        <AddWorkerDialog
                            schoolId={Number(id)}
                            fetchWorkers={fetchWorkers}
                            onClose={() => setAddDialogOpen(false)}
                        />
                    )}

                </Stack>

                <DataGrid
                    columns={columns}
                    rows={workersList ? workersList : []}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    loading={workersLoading}
                    autoHeight={true}
                    pageSizeOptions={[20]}
                    disableRowSelectionOnClick={true}
                    style={{margin: '0'}}
                    slots={{
                        noRowsOverlay: NoRows
                    }}
                />


            </Box>
        </Box>
    );
};

export default SchoolWorkers;