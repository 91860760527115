import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { useUserStore } from '../../global/store/UserStore';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useNavigate } from 'react-router-dom';
import { getSchoolsStatistic } from '../actions/api';
import { DirectionsCar, ExpandMoreOutlined } from '@mui/icons-material';

const regionsArray = [
    "Астана", "Алматы", "Шымкент",
    "Абайская область", "Акмолинская область", "Актюбинская область",
    "Алматинская область", "Атырауская область", "Восточно-Казахстанская область",
    "Жамбылская область", "Жетысуская область", "Западно-Казахстанская область",
    "Карагандинская область", "Костанайская область", "Кызылординская область",
    "Мангистауская область", "Павлодарская область", "Северо-Казахстанская область",
    "Туркестанская область", "Улытауская область"
]

const SchoolsStatistic = () => {

    const isAdmin = useUserStore((state) => state.isAdmin);
    const currentUser = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();
    const [statistic, setStatistic] = useState<any>({
        regions: {},
        total: 0,
        today: 0
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!isAdmin) {
            snackbar.errorMessage('У вас нет прав доступа к данной странице');
            navigate('/');
        } else {
            fetchStatistic();
        }
    }, []);

    const fetchStatistic = () => {
        setLoading(true);
        getSchoolsStatistic()
            .then((res) => {
                console.log(res);
                setStatistic(res.data);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <Accordion sx={{mt: 2}}>
            <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
            >
                    <Stack flexDirection="row" alignItems="center" sx={{mt: 1, mb: 1}}>
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2, width: 50, height: 50}}
                        >
                            <DirectionsCar fontSize="medium"/>
                        </Avatar>
                        <Stack>
                            <Typography variant="h5">Статистика по регистрации</Typography>
                        </Stack>
                    </Stack>
            </AccordionSummary>
            <AccordionDetails>
                {loading && <CircularProgress />}

                {!loading && (<>
                    <Box flexDirection={'row'}>
                        <Typography variant={'h6'}>Всего зарегистрировано учебных центров: <b>{statistic.total}</b></Typography>
                    </Box>
                    <Divider />
                    <Box flexDirection={'column'}>
                        {regionsArray.map((region) => {
                            return (
                                <Typography variant={'h6'}>{region}: {statistic.regions[region]}</Typography>
                            )
                        })}
                    </Box>
                    <Divider />
                    <Box flexDirection={'row'}>
                        <Typography variant={'h6'}>За сегодня зарегистрировано учебных центров: <b>{statistic.today}</b></Typography>
                    </Box>
                </>)}
            </AccordionDetails>
        </Accordion>
    );
};

export default SchoolsStatistic;
