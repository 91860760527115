import Cookies from "js-cookie";
import {AxiosRequestConfig} from "axios";

// export const BACKEND_URL = 'https://test.kursant.kz/api'
// export const FRONTEND_URL = 'https://test.kursant.kz';
// export const BACKEND_URL = 'http://localhost:5001';
// export const FRONTEND_URL = 'http://localhost:3000';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const MEDIA_URL = process.env.REACT_APP_MEDIA_API_URL;
export const apiConfigGuest = {
    headers: {
        "Content-Type": "application/json",
    }
}

export const apiConfigReg = {
    headers: {
        "Content-Type": "multipart/form-data",
    }
}

export const apiConfigAuthorized = () => ({
    headers: {
        "Authorization": Cookies.get('accessToken') ? `Bearer ${Cookies.get('accessToken')}` : '',
        "Content-Type": "application/json"
    }
})

export const apiConfigRefreshToken = ():AxiosRequestConfig => ({
    headers: {
        "Authorization": Cookies.get('refreshToken') ? `Bearer ${Cookies.get('refreshToken')}` : '',
        "Content-Type": "application/json"
    }
});
