import { Stack, Typography } from '@mui/material'
import React from 'react'

interface PageInsideHeaderProps {
    text: string;
    subtext?: string;
}

const PageInsideHeader = ({text, subtext}: PageInsideHeaderProps) => {
  return (
    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
        <Typography sx={{mt: 3}} variant={'h5'}><b>{text}</b></Typography>
        {subtext && (
            <Typography variant="subtitle1">
                {subtext}
            </Typography>
        )}
    </Stack>
  )
}

export default PageInsideHeader