import React, {useEffect, useState} from 'react';
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Container, Divider,
    Stack,
    Typography
} from '@mui/material';
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useNavigate} from "react-router-dom";
import {getInfoKursant, getStudentPromocodesInfo} from "../actions/api";
import KursantStudyGrid from "../components/KursantStudyGrid";
import { AccessTime, Copyright, ExpandMoreOutlined, House, InfoOutlined, LocalPhone } from '@mui/icons-material';
import KursantDrivingGrid from '../components/KursantDrivingGrid';
import KursantMakeAppointment from '../components/KursantMakeAppointment';
import KursantCertificatesGrid from "../components/KursantCertificatesGrid";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import KursantPromocodeGrid from "../components/KursantPromocodeGrid";
import KursantIcon from '../../global/assets/images/KursantIcon';
import InfoIcon from '@mui/icons-material/Info';

interface KursantInfoDto {
    study: {
        status: boolean,
        rows: any[]
    },
    driving: {
        status?: boolean,
        rows: any[]
    },
    certificates: {
        status?: boolean,
        rows: any[]
    }
}

const KursantPage = () => {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);
    const [kursantInfo, setKursantInfo] = useState<KursantInfoDto>({
        study: {
            status: false,
            rows: []
        },
        driving: {
            status: false,
            rows: []
        },
        certificates: {
            status: false,
            rows: []
        }
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [appointmentDialogOpen, setAppointmentDialogOpen] = useState<boolean>(false);
    const [promocodesData, promocodeLoading, promocodesError, getPromocodesInfo] = useLoadingHook(getStudentPromocodesInfo)

    useEffect(() => {
        if (!currentUser.isKursant) {
            snackbar.errorMessage(`Необходимо войти в качестве Курсанта с использованием ИИН и Фамилии`);
            navigate('/auth-kursant')
        } else {
            fetchKursantInfo();
            fetchPromocodesInfo();
        }
    }, []);



    const fetchKursantInfo = () => {
        setLoading(true);
        getInfoKursant({iin: currentUser.iin, last_name: currentUser.last_name})
            .then((res) => {
                setKursantInfo(res.data);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message ? err.response.data.message : 'Возникла ошибка'); 
            })
        setLoading(false);
    }

    const fetchPromocodesInfo = () => {
        getPromocodesInfo({iin: currentUser.iin, last_name: currentUser.last_name})
            .then((res) => {

            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message ? err.response.data.message : 'Возникла ошибка');
            })
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx, pt: 2, pb: 5}}>

                {/*<Stack>*/}
                {/*    <img*/}
                {/*      src={'/images/banner.png'}*/}
                {/*      style={{*/}
                {/*          borderRadius: '25px',*/}
                {/*          cursor: 'pointer'*/}
                {/*      }}*/}
                {/*      onClick={() => {navigate('/tests')}}*/}
                {/*    />*/}
                {/*</Stack>*/}

                <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                    <Typography sx={{mt: 3}} variant={'h5'}><b>Добро пожаловать в панель Курсанта!</b></Typography>
                    <Stack flexDirection={'row'} sx={{my: '16px'}}>
                        <Avatar
                            sx={{width: '57px', height: '57px', mr: 1.5, fontSize: 24, color: '#99999F', bgcolor: '#D9E8FF'}}
                        >
                            {currentUser.last_name[0]}
                        </Avatar>

                        <Stack flexDirection={'column'} justifyContent={'center'}>
                            <Typography sx={{fontSize: '16px'}}><b>Фамилия:</b> {currentUser.last_name}</Typography>
                            <Typography sx={{fontSize: '12px'}}><b>ИИН:</b> {currentUser.iin}</Typography>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack sx={{mt: 2}}>
                    <Accordion
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                        >
                            <Typography><b>Данные по обучению</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack flexDirection={'row'} alignItems={'center'} sx={{my: 1}}>
                                <InfoOutlined sx={{color: 'gray', mr: 2}}/>
                                <Stack>
                                    <Typography variant={'subtitle2'}><b>Внимание!</b> В данном разделе отображается информация по обучению в автошколах Республики Казахстан.</Typography>
                                    <Typography variant={'subtitle2'}>Поиск информации осуществляется по введеным Вами <b>ИИН и Фамилии.</b></Typography>
                                </Stack>
                            </Stack>
                            <KursantStudyGrid 
                                rows={kursantInfo.study.rows}
                                loading={loading}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                        >
                            <Typography><b>Свидетельства</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack flexDirection={'row'} alignItems={'center'} sx={{my: 1}}>
                                <InfoOutlined sx={{color: 'gray', mr: 2}}/>
                                <Stack>
                                    <Typography variant={'subtitle2'}><b>Внимание!</b> В данном разделе отображается информация о свидетельствах об окончании обучения по подготовке вождения транспортных средств.</Typography>
                                    <Typography variant={'subtitle2'}>Поиск информации осуществляется по введеным Вами <b>ИИН и Фамилии.</b></Typography>
                                    <Typography variant={'subtitle2'}>Электронные свидетельства могут быть уже выданы автошколами или же возможна самостоятельная оплата выдачи.</Typography>
                                </Stack>
                            </Stack>

                            <KursantCertificatesGrid
                                rows={kursantInfo.certificates.rows}
                                loading={loading}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                        >
                            <Typography><b>Записи на вождение</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack flexDirection={'row'} alignItems={'center'} sx={{my: 1}}>
                                <InfoOutlined sx={{color: 'gray', mr: 2}}/>
                                <Stack>
                                    <Typography variant={'subtitle2'}><b>Внимание!</b> В данном разделе отображается информация о записях на вождение к мастерам обучения вождению/мастерам производственного обучения (инструкторам).</Typography>
                                    <Typography variant={'subtitle2'}>Также в данном разделе отображается статус записи - Подтверждена / В ожидании / Отклонена.</Typography>
                                    <Typography variant={'subtitle2'}>Поиск информации осуществляется по введеным Вами <b>ИИН и Фамилии.</b></Typography>
                                </Stack>
                            </Stack>

                            <Button
                                onClick={() => {setAppointmentDialogOpen(true)}}
                                variant={'contained'}
                                sx={{my: 2}}
                            >
                                Записаться на вождение
                            </Button>

                            <KursantDrivingGrid 
                                rows={kursantInfo.driving.rows}
                                loading={loading}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                        >
                            <Typography><b>Тесты</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack flexDirection={'row'} alignItems={'center'} sx={{my: 1}}>
                                <InfoOutlined sx={{color: 'gray', mr: 2}}/>
                                <Stack>
                                    <Typography variant={'subtitle2'}>В данном разделе вы можете просмотреть свои результаты по пробной сдаче теоретической части экзамена на водительское удостоверение.</Typography>
                                    <Typography variant={'subtitle2'}><b>Внимание! В таблице указаны только использованные активационные коды. Для просмотра своих купленных, но не активированных кодов - необходимо зайти на свою электронную почту, указанную при покупке.</b></Typography>
                                </Stack>
                            </Stack>

                            <Button
                                onClick={() => navigate(`/tests`)}
                                variant={'contained'}
                                sx={{my: 2}}
                            >
                                Перейти на страницу тестов
                            </Button>

                            <KursantPromocodeGrid
                                rows={!promocodesData ? [] : promocodesData}
                                loading={promocodeLoading}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Stack>

                <KursantMakeAppointment 
                    iin={currentUser.iin}
                    last_name={currentUser.last_name}
                    onClose={() => {setAppointmentDialogOpen(false)}}
                    open={appointmentDialogOpen}
                    fetchKursantInfo={fetchKursantInfo}
                />
            </Box>

            <Divider />

            <Box component="footer" sx={{ bgcolor: 'background.paper', py: 2, marginTop: 'auto'}}>
                <Container maxWidth="xl">
                    <Stack flexDirection={'row'} justifyContent={'space-between'}>
                        <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <KursantIcon width={'125px'} height={'100px'} />
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Typography variant={'caption'}>
                                    <a href="/UserOffer.docx" download="Пользовательское соглашение Kursant KZ"
                                       target="_blank"
                                       rel="noreferrer"
                                       style={{
                                           color: '#ce8838',
                                           textDecoration: 'none'
                                       }}
                                    >
                                        Пользовательское соглашение
                                    </a>
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Typography variant={'caption'}>
                                    <a href="/privacy.docx" download="Политика конфиденциальности Kursant KZ"
                                       target="_blank"
                                       rel="noreferrer"
                                       style={{
                                           color: '#ce8838',
                                           textDecoration: 'none'
                                       }}
                                    >
                                        Политика конфиденциальности
                                    </a>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <LocalPhone fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'}>
                                    <b>Телефон - 8(776)-168-44-11</b>
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <AccessTime fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'}>
                                    10:00 - 18:00 (GMT +6)
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <Copyright fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'} textAlign={'right'}>
                                    ТОО "Global Driving", Kursant KZ, 2023 г.
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <InfoIcon fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'} textAlign={'right'}>
                                    БИН: 230840010667
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <House fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'} textAlign={'right'}>
                                    Астана, р. Алматы, ул. Кайрата Рыскулбекова, Дом 27/1
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
};

export default KursantPage;